import React, { useEffect, useState } from "react";
import { GeneralStyledContent } from "../../../../styled/common-styled";
import { Formik } from "formik";
import * as yup from "yup";
import {
  Form,
  Row,
  Col,
  Input,
  Select,
  Button,
  Switch,
  Upload,
  message,
  Icon,
  Spin,
  Progress,
} from "antd";
import LangContext from "modules/shared/context/langContext";
import RichText from "./Richtext";
import { getNewsCategory, uploadImg } from "../fetchApi/apiClient";

const FormItem = Form.Item;
const Option = Select.Option;

const FormNews = ({ initialValues, readOnly, onSubmit, imgList }) => {
  const [newsCategory, setNewsCategory] = useState([]);
  const [uploadFile, setUploadFile] = useState([]);
  const [validationUploadFile, setValidationUploadFile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    getNewsCategory({}, (status, res) => {
      setNewsCategory(res.data.data);
    });
  }, []);

  useEffect(() => {
    console.log("initialValues", initialValues);
  }, [initialValues]);

  useEffect(() => {
    if (imgList != undefined && imgList.length != 0) {
      console.log("useEffect", imgList);
      setUploadFile(imgList);
    }
  }, [imgList]);

  const handleChangeSelect = (value, setFieldValue) => {
    console.log("🚀 ~ handleChangeSelect ~ value:", value);
    setFieldValue("newsCatagory", value);
  };

  const handleChangeDescription = (value, setFieldValue) => {
    setFieldValue("description", value);
  };

  const onChangePublish = (val, setFieldValue) => {
    setFieldValue("isPublish", val);
  };

  const handleChangeTitleName = (value, setFieldValue) => {
    setFieldValue("titleName", value);
  };

  const handleUploadChange = (info, setFieldValue) => {
    if (info.file.status === "removed") {
      setProgress(0);
      return setUploadFile([]);
    }
    const isJpgOrPng =
      info.file.type === "image/jpeg" || info.file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG files!");
      return;
    }
    const formData = new FormData();
    formData.append("file", info.file.originFileObj);
    setLoading(true);
    uploadImg(
      formData,
      (status, res) => {
        setUploadFile([
          {
            uid: info.file.uid,
            name: "",
            status: info.file.status,
            url: res.data.data,
            thumbUrl: res.data.data,
          },
        ]);
        setValidationUploadFile(false);
        setLoading(false);
      },
      (progress) => {
        setProgress(progress);
      }
    );
  };

  const previewUploadChange = (file) => {
    const newTab = window.open();
    newTab.document.write(
      `<img src="${file.url}" style="max-width:100%;height:auto;"/>`
    );
    newTab.document.title = file.name;
  };

  return (
    <Spin spinning={loading}>
      <LangContext.Consumer>
        {(i18n) => (
          <GeneralStyledContent>
            <div
              style={{
                border: "1px solid gray",
                borderRadius: 8,
                padding: 20,
                margin: 10,
              }}
            >
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={yup.object().shape({
                  titleName: yup.string().required("กรุณากรอกชื่อหัวข้อ"),
                  description: yup.string().required("กรุณากรอกรายละเอียด"),
                  newsCatagory: yup.string().required("กรุณาเลือกหมวดข่าวสาร"),
                  content: yup
                    .string()
                    .test("is-not-empty-html", "กรุณากรอกคำอธิบาย", (value) => {
                      // Check if the content contains visible text, excluding HTML tags
                      const strippedContent = value
                        .replace(/<[^>]*>/g, "")
                        .trim();
                      return strippedContent.length > 0; // Only valid if there is non-whitespace content
                    }),
                })}
                onSubmit={(values) => {
                  if (uploadFile.length === 0) {
                    setValidationUploadFile(true);
                    message.error("กรุณาอัพโหลดรูปปก"); // Show error message
                    return; // Prevent form submission
                  }

                  setValidationUploadFile(false);
                  onSubmit({
                    ...values,
                    cover: uploadFile,
                  });
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <Form onSubmit={handleSubmit} layout="vertical">
                    <Row gutter={[12, 12]}>
                      {/* {JSON.stringify(values)} */}
                      <Col span={24}>
                        <label>{i18n.d.detail}</label>
                      </Col>
                      {/* Title Field */}
                      <Col span={8}>
                        <FormItem
                          label={i18n.t.titleName}
                          required
                          validateStatus={
                            touched.titleName && errors.titleName && "error"
                          }
                          help={touched.titleName && errors.titleName}
                        >
                          <Input
                            size="large"
                            readOnly={readOnly}
                            name="titleName"
                            value={values.titleName}
                            onChange={(e) =>
                              handleChangeTitleName(
                                e.target.value,
                                setFieldValue
                              )
                            }
                            onBlur={handleBlur}
                            placeholder={i18n.f.fillInfomation}
                            style={{ width: "100%" }}
                          />
                        </FormItem>
                      </Col>
                      <Col span={8}>
                        <FormItem
                          label={i18n.d.detail}
                          required
                          validateStatus={
                            touched.description && errors.description && "error"
                          }
                          help={touched.description && errors.description}
                        >
                          <Input
                            size="large"
                            readOnly={readOnly}
                            name="Description"
                            value={values.description}
                            onChange={(e) =>
                              handleChangeDescription(
                                e.target.value,
                                setFieldValue
                              )
                            }
                            onBlur={handleBlur}
                            placeholder={"กรอกรายละเอียด"}
                            style={{ width: "100%" }}
                          />
                        </FormItem>
                      </Col>
                      <Col span={8} />
                    </Row>
                    <Row gutter={[12, 12]}>
                      {/* Category Field */}
                      <Col span={8}>
                        <FormItem
                          label={i18n.newsManuals.newsCategory}
                          required
                          validateStatus={
                            touched.newsCatagory &&
                            errors.newsCatagory &&
                            "error"
                          }
                          help={touched.newsCatagory && errors.newsCatagory}
                        >
                          <Select
                            size="large"
                            disabled={readOnly}
                            value={values.newsCatagory}
                            style={{ width: "100%" }}
                            onChange={(val) =>
                              handleChangeSelect(val, setFieldValue)
                            }
                          >
                            {newsCategory.map((item) => (
                              <Option value={item.id.toString()}>
                                {i18n.newsManuals[item.name]}
                              </Option>
                            ))}
                          </Select>
                        </FormItem>
                      </Col>

                      <Col span={16} />
                    </Row>
                    <Row gutter={[12, 12]}>
                      <Col span={8}>
                        <FormItem
                          label={i18n.u.upload}
                          required
                          validateStatus={validationUploadFile && "error"}
                          help={validationUploadFile && "กรุณาอัพโหลดรูปปก"}
                        >
                          <Upload
                            listType={"picture"}
                            onChange={(info) => {
                              handleUploadChange(info, setFieldValue);
                            }}
                            fileList={uploadFile}
                            onPreview={previewUploadChange}
                          >
                            <Button>
                              <Icon type="upload" /> Upload
                            </Button>{" "}
                            {uploadFile.length > 0 ||
                              (progress > 0 && (
                                <Progress
                                  type="circle"
                                  percent={progress}
                                  width={20}
                                />
                              ))}
                          </Upload>
                        </FormItem>
                      </Col>
                      <Col span={16} />
                    </Row>
                    <Row gutter={[12, 12]}>
                      {/* Content Field with Slate */}
                      <Col span={16}>
                        <FormItem
                          label={i18n.c.content}
                          required
                          validateStatus={
                            touched.content && errors.content && "error"
                          }
                          help={touched.content && errors.content}
                        >
                          <div
                            style={{
                              border: "1px solid",
                              borderColor:
                                touched.content && errors.content
                                  ? "red"
                                  : "#d9d9d9",
                              borderRadius: 4,
                              padding: 8,
                              width: "100%",
                              minHeight: 150,
                            }}
                          >
                            {values.content && (
                              <RichText
                                description={values.content}
                                disabled={readOnly}
                                setDescription={(val) => {
                                  setFieldValue("content", val);
                                }}
                              />
                            )}

                            {!values.content && !false && (
                              <RichText
                                description={values.content}
                                disabled={readOnly}
                                setDescription={(val) => {
                                  setFieldValue("content", val);
                                }}
                              />
                            )}
                          </div>
                        </FormItem>
                      </Col>
                      <Col span={12}>
                        <Switch
                          disabled={readOnly}
                          checkedChildren="Publish"
                          unCheckedChildren="Publish"
                          checked={values.isPublish}
                          onChange={(val) =>
                            onChangePublish(val, setFieldValue)
                          }
                        />
                      </Col>
                      {/* Submit Button */}
                      <Col span={12} style={{ textAlign: "right" }}>
                        <Button
                          onClick={() => {
                            window.location.replace("/news-manuals");
                          }}
                        >
                          {i18n.b.back}
                        </Button>
                        {!readOnly && (
                          <Button
                            type="primary"
                            htmlType="submit"
                            style={{ marginLeft: 10 }}
                            onClick={() => {
                              if (uploadFile.length === 0) {
                                return setValidationUploadFile(true);
                              }
                              return setValidationUploadFile(false);
                            }}
                          >
                            {i18n.s.submit}
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </div>
          </GeneralStyledContent>
        )}
      </LangContext.Consumer>
    </Spin>
  );
};

export default FormNews;
