import axios from "axios";
import { getErrorMsg } from "lib/fetch";

// const axiosClient = axios.create({
//   baseURL: process.env.REACT_APP_LARAVEL_API_ENDPOINT,
//   headers: {
//     Accept: "application/json",
//     "Content-Type": "application/json",
//     Authorization: `Bearer ${localStorage.getItem("access-token")}`,
//   },
// });

const axiosClientFromWeb = axios.create({
  baseURL: `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("access-token")}`,
  },
});

let axiosClient = null;

export const createAxios = (token) => {
  axiosClient = axios.create({
    baseURL: `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  axiosClient.interceptors.response.use(
    function(response) {
      return response;
    },
    function(error) {
      let res = error.response;
      if (res.status === 401) {
        window.location.href = "./";
      }
      // console.error(`Looks like there was a problem. Status Code: ` + res.status);
      return Promise.reject(error);
    }
  );
};

// Generic function to handle API requests
const apiRequest = async (method, url, params, callback) => {
  try {
    const res = await axiosClient[method](url, params);
    callback(200, res);
  } catch (error) {
    // Handle error appropriately, e.g., logging, showing user-friendly message
    console.error("API Error:", error);
    // Example error handling:
    // if (error.response) {
    //   callback(error.response.status, error.response.data);
    // } else {
    callback(500, getErrorMsg(error));
    // }
  }
};

// Centralized error handling function (example)
const handleError = (error, callback) => {
  // Handle error here or pass it to callback

  console.error("API Error:", error);
  //   callback(error.response?.status, error.response?.data);
};

// API functions using generic request function
export async function loadPolicy(params, callback) {
  await apiRequest("post", "/new-policy", params, callback);
}

export async function loadPolicyType(type, params, callback) {
  try {
    const res = await axiosClientFromWeb.get(`/policy-type${type}`, { params });
    callback(200, res);
  } catch (error) {
    handleError(error);
  }
}

export async function deletePolicy(id, callback) {
  await apiRequest("delete", `/new-policy/${id}`, null, callback);
}

// export async function loadVehicleGroup(params, callback) {
//   await apiRequest(
//     "post",
//     "/vehicle-group/vehicle-group-by-company",
//     params,
//     callback
//   );
// }

export async function loadVehicleGroup(params, callback) {
  try {
    const res = await axiosClientFromWeb.post(
      "/vehicle-group/vehicle-group-by-company",
      params
    );
    callback(200, res);
  } catch (error) {
    handleError(error);
  }
}

export async function loadVehicle(params, callback) {
  try {
    const res = await axiosClientFromWeb.post(
      "/vehicle/vehicle-by-company",
      params
    );
    callback(200, res);
  } catch (error) {
    handleError(error);
  }
}

export async function loadVehicleByVehicleGroup(params, callback) {
  try {
    const res = await axiosClientFromWeb.post(
      "/vehicle/vehicle-by-vehicle-group",
      params
    );
    callback(200, res);
  } catch (error) {
    handleError(error);
  }
}

export async function createPolicy(params, callback) {
  await apiRequest("post", "/new-policy/save", params, callback);
}

export async function loadZoneByCompony(params, callback) {
  await apiRequest("post", "/zone/zone-by-company", params, callback);
}

export async function loadPolicyById(id, callback) {
  try {
    const res = await axiosClientFromWeb.get(`/new-policy/${id}`, {});
    callback(200, res);
  } catch (error) {
    handleError(error);
  }
}

export async function updatePolicy(id, params, callback) {
  try {
    const res = await axiosClientFromWeb.put(`/new-policy/${id}`, params);
    callback(200, res);
  } catch (error) {
    handleError(error);
  }
}

export async function getAudioList(params, callback) {
  await apiRequest("post", "/policy-audio/list", params, callback);
}

export async function loadUserByUserGroup(params, callback) {
  try {
    const res = await axiosClientFromWeb.post(
      "/masterdata/getuserbygroup",
      params
    );
    callback(200, res);
  } catch (error) {
    handleError(error);
  }
}

export async function loadUserGroup(companyId, callback) {
  try {
    const res = await axiosClientFromWeb.get(
      `/masterdata/getcompanyusergroup/${companyId}`,
      {}
    );
    callback(200, res);
  } catch (error) {
    handleError(error);
  }
}

export async function loadPolicyNoti(id, callback) {
  await apiRequest("get", `/new-policy/noti/${id}`, {}, callback);
}

export async function updatePolicyNoti(id, params, callback) {
  await apiRequest("put", `/new-policy/noti/${id}`, params, callback);
}
