import React, { Component } from "react";
import { Row, Col, Icon, Button, Drawer, Typography, Divider } from "antd";
import { helper_convertDateformat } from "../../../../lib/helper";
import LangContext, { i18n } from "modules/shared/context/langContext";
import { checkPermissions } from "../../../../lib/helper";
import * as moment from "moment";
import ModalMdvr from "./MDVR LIVE STREAM";
import OfflineModal from "./MDVR LIVE STREAM/OfflineModal";
import SnapShot from "./SNAPSHOT";

const { Title, Paragraph, Text } = Typography;

export class PopupDrawerNew extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visibleMDVR: false,
      offlineModal: false,
      modalData: [],
      jsessionid: "",
      vehList: "",
      platenoList: "",
      numberOfChanel: "",
      snapShotModal: false,
    };
  }

  componentDidMount() {}

  onCloseMdvr = () => {
    this.setState({
      visibleMDVR: false,
    });
  };

  onCloseSnapShot = () => {
    this.setState({
      snapShotModal: false,
    });
  };

  handleMDVRMODAL = () => {
    this.props.loadListVehicleMdvr(
      {
        company_id: this.props.companyId,
        vehicle_visibility: this.props.selectedVehicle.vehicle_id,
        list_vehicle_id: [],
      },
      true,
      (res) => {
        let self = this;
        if (res.data[0].online == 1) {
          self.setState({
            visibleMDVR: true,
            modalData: res,
            vehList: res.data[0].devidno,
            platenoList: res.data[0].plate_no,
            numberOfChanel: res.data[0].number_of_channel,
          });
        } else {
          self.setState({
            offlineModal: true,
          });
        }
      }
    );
  };

  handleSnapShot = () => {
    this.setState({
      snapShotModal: true,
    });
  };

  handleWatchVideoRealTime = () => {
    this.props.onClose();
    this.props.handleShowHNTLiveStream(
      true,
      this.props.selectedVehicle.vehicle_id
    );
    this.props.loadListVehicleHoneytoast(
      {
        company_id: this.props.companyId,
        list_vehicle_id: "",
        orderBy: "plate_no",
        orderType: "asc",
        vehicle_visibility: this.props.selectedVehicle.vehicle_id,
      },
      true
    );
    this.props.handleVehicleSelectedChange(
      true,
      this.props.trackingList,
      this.props.selectedVehicle.vehicle_id
    );
  };

  hideOfflineModal = () => {
    this.setState({ offlineModal: false });
  };

  handleLinkCreateLocaion = () => {
    let url = window.location.origin;
    window.open(
      `${url}/location/create/${this.props.selectedVehicle.lat}/${this.props.selectedVehicle.lng}`
    );
  };

  convertDurationRange = (text, i18n) => {
    let d = "";
    let hrMin = "";
    if (text != "") {
      hrMin = text.split(":");
      if (text.includes("d")) {
        d = text.split("d");
        return (
          d[0] +
          " " +
          i18n.d.day +
          " " +
          hrMin[0].split(" ")[1] +
          " " +
          i18n.h.hours +
          " " +
          hrMin[1] +
          " " +
          i18n.m.minute
        );
      }
      return (
        hrMin[0] + " " + i18n.h.hours + " " + hrMin[1] + " " + i18n.m.minute
      );
    }
  };

  render() {
    const openReplay = () => {
      let st = moment().format("YYYY-MM-DD 00:00:00");
      let ed = moment().format("YYYY-MM-DD 23:59:00");

      window.open(
        `${window.location.origin}/replay?vehicle_id=${this.props.selectedVehicle.vehicle_id}&start_at=${st}&end_at=${ed}`,
        "_blank"
      );
    };

    return (
      <div className="container">
        <Drawer
          placement={this.props.placement}
          onClose={this.props.onClose}
          visible={this.props.visible}
          height={"auto"}
          className="tracking-drawer-mask"
          maskClosable={false}
          mask={false}
        >
          <Row
            type={
              this.props.columns.includes("fuel_status") ||
              this.props.columns.includes("boom") ||
              this.props.columns.includes("temperature1")
                ? ""
                : "flex"
            }
            justify="center"
          >
            <Col xs={24} sm={24} md={24} lg={4} xl={4} xxl={4} align="center">
              <div className="driver_license_info_box">
                <Col xs={0} sm={0} md={0} lg={0} xl={0} xxl={0}></Col>
                <Col
                  className="tracking-box-dirver"
                  xs={11}
                  sm={11}
                  md={11}
                  lg={24}
                  xl={24}
                  xxl={24}
                >
                  <h3
                    style={{
                      color: "black",
                      fontSize: "1vw",
                      marginBottom: "5px",
                    }}
                  >
                    <LangContext.Consumer>
                      {(i18n) => i18n.d.driverData}
                    </LangContext.Consumer>{" "}
                  </h3>
                  <div>
                    <h3
                      style={{
                        color: "gray",
                        display: "inline",
                        fontSize: "0.75vw",
                      }}
                    >
                      <LangContext.Consumer>
                        {(i18n) => i18n.d.driverName}
                      </LangContext.Consumer>
                      {" : "}
                    </h3>
                    <h3
                      style={{
                        display: "inline",
                        color: "black",
                        fontSize: "0.75vw",
                      }}
                    >
                      {this.props.selectedVehicle != ""
                        ? this.props.selectedVehicle.driver1
                        : "-"}
                    </h3>
                  </div>
                  <div>
                    <h3
                      style={{
                        color: "gray",
                        display: "inline",
                        fontSize: "0.75vw",
                      }}
                    >
                      <LangContext.Consumer>
                        {(i18n) => i18n.c.cardId}
                      </LangContext.Consumer>
                    </h3>
                    <h3
                      style={{
                        display: "inline",
                        color: "black",
                        fontSize: "0.75vw",
                      }}
                    >
                      -
                    </h3>
                  </div>
                </Col>
                <Col xs={2} sm={2} md={2} lg={0} xl={0} xxl={0} align="center">
                  <Divider
                    type="vertical"
                    style={{
                      height: "5rem",
                      color: "black",
                    }}
                  />
                </Col>
                <Col
                  className="tracking-box-swipe"
                  xs={10}
                  sm={10}
                  md={10}
                  lg={24}
                  xl={24}
                  xxl={24}
                >
                  <div>
                    <h3
                      style={{
                        color: "black",
                        fontSize: "1vw",
                        marginBottom: "5px",
                      }}
                    >
                      <LangContext.Consumer>
                        {(i18n) => i18n.d.DrivingNamelicensescanlogReport}
                      </LangContext.Consumer>
                    </h3>
                    <h3
                      style={{
                        color: "gray",
                        display: "inline",
                        fontSize: "0.75vw",
                      }}
                    >
                      <LangContext.Consumer>
                        {(i18n) => i18n.d.driverName}
                      </LangContext.Consumer>
                      {" : "}
                    </h3>
                    <h3
                      style={{
                        display: "inline",
                        color: "black",
                        fontSize: "0.75vw",
                      }}
                    >
                      {this.props.selectedVehicle.driver_license_info !=
                      null ? (
                        <>
                          {this.props.selectedVehicle.driver_license_info
                            .fname != "" ? (
                            <>
                              {
                                this.props.selectedVehicle.driver_license_info
                                  .fname
                              }{" "}
                              {
                                this.props.selectedVehicle.driver_license_info
                                  .lname
                              }
                            </>
                          ) : (
                            "-"
                          )}
                        </>
                      ) : (
                        "-"
                      )}
                    </h3>
                  </div>

                  <div>
                    <h3
                      style={{
                        color: "gray",
                        display: "inline",
                        fontSize: "0.75vw",
                      }}
                    >
                      <LangContext.Consumer>
                        {(i18n) => i18n.c.cardId}
                      </LangContext.Consumer>
                    </h3>
                    <h3
                      style={{
                        color: "black",
                        fontSize: "0.75vw",
                        display: "inline",
                      }}
                    >
                      -
                    </h3>
                  </div>
                </Col>
                <Col xs={11} sm={11} md={11} lg={9} xl={9} xxl={0}></Col>
              </div>
            </Col>

            <Col xs={24} sm={24} md={24} lg={0} xl={0} xxl={0} align="center">
              <div className="divider_web_content" style={{ marginTop: "5px" }}>
                <Divider
                  type="horizontal"
                  style={{ height: "1px", color: "black" }}
                />
              </div>
            </Col>
            <Col xs={0} sm={0} md={0} lg={0} xl={1} xxl={1} align="center">
              <Divider
                type="vertical"
                style={{ height: "11rem", color: "black" }}
              />
            </Col>
            <Col xs={0} sm={0} md={0} lg={1} xl={0} xxl={0} align="center">
              <Divider
                type="vertical"
                style={{ height: "8rem", color: "black" }}
              />
            </Col>
            <Col xs={14} sm={14} md={14} lg={10} xl={10} xxl={10} align="left">
              <div style={{ marginTop: "0.2rem", color: "black" }}>
                <Row>
                  <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                    <Title level={4} style={{ fontSize: "1vw" }}>
                      <LangContext.Consumer>
                        {(i18n) => i18n.v.vehicle}
                      </LangContext.Consumer>
                    </Title>
                  </Col>
                </Row>
                <Row gutter={[0, 16]}>
                  {/* ทะเบียน */}
                  <Col
                    xs={8}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                    style={{ fontSize: "0.75vw" }}
                  >
                    <label>
                      <LangContext.Consumer>
                        {(i18n) => i18n.p.plateNo}
                      </LangContext.Consumer>
                      {" : "}
                    </label>
                    <label style={{ fontWeight: "bold" }}>
                      {this.props.selectedVehicle.plate_no}
                    </label>
                  </Col>
                  {/* สถานะเครื่องยนต์ */}
                  <Col
                    xs={7}
                    md={7}
                    lg={7}
                    xl={7}
                    xxl={7}
                    style={{ fontSize: "0.75vw" }}
                  >
                    <label>
                      <LangContext.Consumer>
                        {(i18n) => i18n.e.engineStatus}
                      </LangContext.Consumer>
                      {" : "}
                    </label>
                    <label>
                      {this.props.selectedVehicle.engine == 1 ? (
                        <>
                          <label class="red-dot"></label>{" "}
                          <label style={{ fontWeight: "bold" }}>OFFLINE</label>
                        </>
                      ) : (
                        <>
                          <label class="green-dot"></label>{" "}
                          <label style={{ fontWeight: "bold" }}>ONLINE</label>
                        </>
                      )}
                    </label>
                  </Col>
                  {/* ระยะเวลาสถานะ */}
                  <Col
                    xs={9}
                    md={9}
                    lg={9}
                    xl={9}
                    xxl={9}
                    style={{ fontSize: "0.75vw" }}
                  >
                    <label>
                      <LangContext.Consumer>
                        {(i18n) => i18n.d.durationRange}
                      </LangContext.Consumer>
                      {" : "}
                    </label>
                    <LangContext.Consumer>
                      {(i18n) => (
                        <label style={{ fontWeight: "bold" }}>
                          {this.props.selectedVehicle.duration_range != ""
                            ? this.convertDurationRange(
                                this.props.selectedVehicle.duration_range,
                                i18n
                              )
                            : "?"}
                        </label>
                      )}
                    </LangContext.Consumer>
                  </Col>
                </Row>
                <Row gutter={[0, 16]}>
                  {/* รหัสพาหนะ */}
                  <Col
                    xs={8}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                    style={{ fontSize: "0.75vw" }}
                  >
                    <label>
                      <LangContext.Consumer>
                        {(i18n) => i18n.v.vnCode}
                      </LangContext.Consumer>
                      {" : "}
                    </label>
                    <label style={{ fontWeight: "bold" }}>
                      {this.props.selectedVehicle.vehicle_code}
                    </label>
                  </Col>
                  {/* รุ่นพาหนะ */}
                  <Col
                    xs={7}
                    md={7}
                    lg={7}
                    xl={7}
                    xxl={7}
                    style={{ fontSize: "0.75vw" }}
                  >
                    <LangContext.Consumer>
                      {(i18n) => i18n.v.vehicleModel}
                    </LangContext.Consumer>
                    {" : "}
                    <label style={{ fontWeight: "bold" }}>
                      {this.props.selectedVehicle.model != ""
                        ? this.props.selectedVehicle.model
                        : "-"}
                    </label>
                  </Col>
                  {/* ความเร็ว */}
                  <Col
                    xs={9}
                    md={9}
                    lg={9}
                    xl={9}
                    xxl={9}
                    style={{ fontSize: "0.75vw" }}
                  >
                    <label>
                      <LangContext.Consumer>
                        {(i18n) => i18n.s.speed}
                      </LangContext.Consumer>
                      {" : "}
                    </label>
                    <label style={{ fontWeight: "bold" }}>
                      {this.props.selectedVehicle.speed} km/h
                    </label>
                  </Col>
                </Row>
                <Row gutter={[0, 16]}>
                  {/* สถานะแบตสำรอง */}
                  <Col
                    xs={8}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                    style={{ fontSize: "0.75vw" }}
                  >
                    <label>
                      <LangContext.Consumer>
                        {(i18n) => i18n.p.powerBankStatus}
                      </LangContext.Consumer>
                      {" : "}
                    </label>
                    <label style={{ fontWeight: "bold" }}>
                      {!!this.props.selectedVehicle.pw_supply ? (
                        this.props.selectedVehicle.pw_supply["batt"][0] == 1 ? (
                          <LangContext.Consumer>
                            {(i18n) => i18n.p.present}
                          </LangContext.Consumer>
                        ) : (
                          <LangContext.Consumer>
                            {(i18n) => i18n.n.nope}
                          </LangContext.Consumer>
                        )
                      ) : (
                        "-"
                      )}
                    </label>
                  </Col>
                  {/* อุปกรณ์ดึงไฟจาก */}
                  <Col
                    xs={7}
                    md={7}
                    lg={7}
                    xl={7}
                    xxl={7}
                    style={{ fontSize: "0.75vw" }}
                  >
                    <label>
                      <LangContext.Consumer>
                        {(i18n) => i18n.p.powerSupply}
                      </LangContext.Consumer>
                      {" : "}
                    </label>
                    <label style={{ fontWeight: "bold" }}>
                      {!!this.props.selectedVehicle.pw_supply ? (
                        this.props.selectedVehicle.pw_supply["current_src"] ==
                        "DC" ? (
                          <LangContext.Consumer>
                            {(i18n) => i18n.p.powerFromVehicle}
                          </LangContext.Consumer>
                        ) : (
                          <LangContext.Consumer>
                            {(i18n) => i18n.p.powerBank}
                          </LangContext.Consumer>
                        )
                      ) : (
                        "-"
                      )}
                    </label>
                  </Col>
                  {/* กำลังไฟ */}
                  <Col
                    xs={9}
                    md={9}
                    lg={9}
                    xl={9}
                    xxl={9}
                    style={{ fontSize: "0.75vw" }}
                  >
                    <label>
                      <LangContext.Consumer>
                        {(i18n) => i18n.p.power}
                      </LangContext.Consumer>
                      {" : "}
                    </label>

                    {!!this.props.selectedVehicle.pw_supply ? (
                      this.props.selectedVehicle.pw_supply["current_src"] ==
                      "DC" ? (
                        <label style={{ fontWeight: "bold" }}>
                          {parseInt(
                            this.props.selectedVehicle.pw_supply["dc_vol"][1] *
                              Math.pow(10, 2)
                          ) /
                            Math.pow(10, 2).toFixed(2) +
                            " V"}
                        </label>
                      ) : (
                        <label style={{ fontWeight: "bold" }}>
                          {parseInt(
                            this.props.selectedVehicle.pw_supply["batt"][1] *
                              Math.pow(10, 2)
                          ) /
                            Math.pow(10, 2).toFixed(2) +
                            " v."}
                        </label>
                      )
                    ) : (
                      "-"
                    )}
                  </Col>
                </Row>
                <Row gutter={[0, 16]}>
                  {/* ที่อยู่ปัจจุบัน */}
                  <Col
                    xs={8}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                    style={{ fontSize: "0.75vw" }}
                  >
                    <label>
                      <LangContext.Consumer>
                        {(i18n) => i18n.c.currentAddress}
                      </LangContext.Consumer>
                      {" : "}
                    </label>
                    <label style={{ fontWeight: "bold" }}>
                      {this.props.selectedVehicle.address}
                    </label>
                  </Col>
                  {/* Lat */}
                  <Col
                    xs={7}
                    md={7}
                    lg={7}
                    xl={7}
                    xxl={7}
                    style={{ fontSize: "0.75vw" }}
                  >
                    <LangContext.Consumer>
                      {(i18n) => i18n.l.latitude}
                    </LangContext.Consumer>
                    {" : "}
                    <label style={{ fontWeight: "bold" }}>
                      {this.props.selectedVehicle.lat != ""
                        ? this.props.selectedVehicle.lat
                        : "-"}
                    </label>
                  </Col>
                  {/* Lng */}
                  <Col
                    xs={9}
                    md={9}
                    lg={9}
                    xl={9}
                    xxl={9}
                    style={{ fontSize: "0.75vw" }}
                  >
                    <label>
                      <LangContext.Consumer>
                        {(i18n) => i18n.l.longitude}
                      </LangContext.Consumer>
                      {" : "}
                    </label>
                    <label style={{ fontWeight: "bold" }}>
                      {this.props.selectedVehicle.lng != ""
                        ? this.props.selectedVehicle.lng
                        : "-"}
                    </label>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col
              xs={0}
              sm={0}
              md={0}
              lg={0}
              xl={0}
              xxl={0}
              align="center"
            ></Col>
            <Col xs={0} sm={0} md={0} lg={0} xl={1} xxl={1} align="center">
              <Divider
                type="vertical"
                style={{ height: "11rem", color: "black" }}
              />
            </Col>
            <Col xs={1} sm={1} md={1} lg={1} xl={0} xxl={0} align="center">
              <Divider
                type="vertical"
                style={{ height: "8rem", color: "black" }}
              />
            </Col>
            <Col xs={5} sm={5} md={4} lg={4} xl={4} xxl={4} align="left">
              <div style={{ marginTop: "0.2rem", color: "black" }}>
                <Row>
                  <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                    <Title level={4} style={{ fontSize: "1vw" }}>
                      Support
                    </Title>
                  </Col>
                </Row>
                <Row gutter={[8, 16]}>
                  {/* รหัสกล่อง */}
                  <Col
                    xs={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    style={{ fontSize: "0.75vw" }}
                  >
                    <label>
                      <LangContext.Consumer>
                        {(i18n) => i18n.b.boxID}
                      </LangContext.Consumer>{" "}
                      :{" "}
                    </label>
                    <label style={{ fontWeight: "bold" }}>
                      {" "}
                      {this.props.selectedVehicle.device_code}
                    </label>
                  </Col>
                </Row>
                <Row gutter={[8, 16]}>
                  {/* อัพเดตตำแหน่ง */}
                  <Col
                    xs={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    style={{ fontSize: "0.75vw" }}
                  >
                    <label>
                      <LangContext.Consumer>
                        {(i18n) => i18n.u.updateLocation}
                      </LangContext.Consumer>{" "}
                      :{" "}
                    </label>
                    <label style={{ fontWeight: "bold" }}>
                      {" "}
                      {helper_convertDateformat(
                        this.props.selectedVehicle.latest_pos_update_at,
                        "YYYY-MM-DD HH:mm",
                        "DD/MM/YY HH:mm"
                      ) == "NOW" ? (
                        <>
                          <label class="green-dot"></label>{" "}
                          <label>
                            {helper_convertDateformat(
                              this.props.selectedVehicle.latest_pos_update_at,
                              "YYYY-MM-DD HH:mm",
                              "DD/MM/YY HH:mm"
                            )}
                          </label>
                        </>
                      ) : (
                        <>
                          <label class="red-dot"></label>{" "}
                          <label>
                            {helper_convertDateformat(
                              this.props.selectedVehicle.latest_pos_update_at,
                              "YYYY-MM-DD HH:mm",
                              "DD/MM/YY HH:mm"
                            )}
                          </label>
                        </>
                      )}
                    </label>
                  </Col>
                </Row>
                <Row gutter={[8, 16]}>
                  {/* เชื่อมต่อสัญญาณ */}
                  <Col
                    xs={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    style={{ fontSize: "0.75vw" }}
                  >
                    <label>
                      <LangContext.Consumer>
                        {(i18n) => i18n.c.connection}
                      </LangContext.Consumer>{" "}
                      :{" "}
                    </label>
                    <label style={{ fontWeight: "bold" }}>
                      {" "}
                      {helper_convertDateformat(
                        this.props.selectedVehicle.gps_active_at,
                        "YYYY-MM-DD HH:mm",
                        "DD/MM/YY HH:mm"
                      ) == "NOW" ? (
                        <>
                          <label class="green-dot"></label>{" "}
                          <label>
                            {helper_convertDateformat(
                              this.props.selectedVehicle.gps_active_at,
                              "YYYY-MM-DD HH:mm",
                              "DD/MM/YY HH:mm"
                            )}
                          </label>
                        </>
                      ) : (
                        <>
                          <label class="red-dot"></label>{" "}
                          <label>
                            {helper_convertDateformat(
                              this.props.selectedVehicle.gps_active_at,
                              "YYYY-MM-DD HH:mm",
                              "DD/MM/YY HH:mm"
                            )}
                          </label>
                        </>
                      )}
                    </label>
                  </Col>
                  {/* จำนวนดาวเทียม */}
                  <Col
                    xs={24}
                    md={24}
                    lg={12}
                    xl={12}
                    xxl={12}
                    style={{ fontSize: "0.75vw" }}
                  >
                    <label>
                      <LangContext.Consumer>
                        {(i18n) => i18n.s.satelliteCount}
                      </LangContext.Consumer>{" "}
                      :{" "}
                    </label>
                    <label style={{ fontWeight: "bold" }}>
                      {this.props.selectedVehicle.satellite == -1
                        ? "N/A"
                        : this.props.selectedVehicle.satellite}{" "}
                      ดวง
                    </label>
                  </Col>
                  {/* ความแรงสัญญาณ */}
                  <Col
                    xs={24}
                    md={24}
                    lg={12}
                    xl={12}
                    xxl={12}
                    style={{ fontSize: "0.75vw" }}
                  >
                    <label>
                      <LangContext.Consumer>
                        {(i18n) => i18n.s.sim}
                      </LangContext.Consumer>{" "}
                      :{" "}
                    </label>
                    <label style={{ fontWeight: "bold" }}>
                      {!!this.props.selectedVehicle.sim
                        ? this.props.selectedVehicle.sim["signal"]
                        : ""}
                    </label>
                  </Col>
                </Row>
              </div>
            </Col>
            {this.props.columns.includes("fuel_status") ||
            this.props.columns.includes("boom") ||
            this.props.columns.includes("temperature1") ? (
              <Col xs={0} sm={0} md={0} lg={0} xl={1} xxl={1} align="center">
                <Divider
                  type="vertical"
                  style={{ height: "11rem", color: "black" }}
                />
              </Col>
            ) : (
              ""
            )}
            {this.props.columns.includes("fuel_status") ||
            this.props.columns.includes("boom") ||
            this.props.columns.includes("temperature1") ? (
              <Col xs={1} sm={1} md={1} lg={1} xl={0} xxl={0} align="center">
                <Divider
                  type="vertical"
                  style={{ height: "8rem", color: "black" }}
                />
              </Col>
            ) : (
              ""
            )}
            {this.props.columns.includes("fuel_status") ||
            this.props.columns.includes("boom") ||
            this.props.columns.includes("temperature1") ? (
              <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3} align="left">
                <div style={{ marginTop: "0.2rem", color: "black" }}>
                  <Row>
                    <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                      <Title level={4} style={{ fontSize: "1vw" }}>
                        <LangContext.Consumer>
                          {(i18n) => i18n.e.etc}
                        </LangContext.Consumer>
                      </Title>
                    </Col>
                  </Row>

                  {this.props.columns.includes("fuel_status") && (
                    <Row gutter={[8, 16]}>
                      {/* เชื้อเพลิง */}
                      <Col
                        xs={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        style={{ fontSize: "0.75vw" }}
                      >
                        <label>
                          <LangContext.Consumer>
                            {(i18n) => i18n.f.fuel}
                          </LangContext.Consumer>
                          {" : "}
                        </label>
                        <label style={{ fontWeight: "bold" }}>
                          {this.props.selectedVehicle.fuel_status}
                        </label>
                      </Col>
                    </Row>
                  )}
                  {this.props.columns.includes("boom") && (
                    <Row gutter={[8, 16]}>
                      {/* Boom */}
                      <Col
                        xs={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        style={{ fontSize: "0.75vw" }}
                      >
                        <label>
                          BOOM
                          {" : "}
                        </label>
                        <label style={{ fontWeight: "bold" }}>
                          {this.props.selectedVehicle.boom == 0 ? (
                            <>
                              {" "}
                              <label class="green-dot"></label>{" "}
                              <label style={{ fontWeight: "bold" }}>
                                <LangContext.Consumer>
                                  {(i18n) => i18n.o.open}
                                </LangContext.Consumer>
                              </label>
                            </>
                          ) : (
                            <>
                              {" "}
                              <label class="red-dot"></label>{" "}
                              <label style={{ fontWeight: "bold" }}>
                                <LangContext.Consumer>
                                  {(i18n) => i18n.c.close}
                                </LangContext.Consumer>
                              </label>
                            </>
                          )}
                        </label>
                      </Col>
                    </Row>
                  )}
                  {this.props.columns.includes("temperature1") && (
                    <Row gutter={[8, 16]}>
                      {/* อุณหภูมิ */}
                      <Col
                        xs={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        style={{ fontSize: "0.75vw" }}
                      >
                        <label>
                          <LangContext.Consumer>
                            {(i18n) => i18n.variable.temperature}
                          </LangContext.Consumer>
                          {" : "}
                        </label>
                        <label style={{ fontWeight: "bold" }}>
                          {this.props.selectedVehicle.temperature1}
                        </label>
                      </Col>
                    </Row>
                  )}
                </div>
              </Col>
            ) : (
              ""
            )}
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              xxl={24}
              align="center"
              style={{ marginTop: 20, marginBottom: 10 }}
            >
              <Row
                type="flex"
                justify="center"
                gutter={[24, 0]}
                style={{ marginTop: "10px" }}
              >
                <Col xs={4} sm={4} md={4} lg={3} xl={3} xxl={3} align="center">
                  {checkPermissions(
                    this.props.permissions,
                    "location",
                    "can_add"
                  ) == true ? (
                    <Button
                      className="button-drawer-popup"
                      onClick={this.handleLinkCreateLocaion}
                      block
                    >
                      <span style={{ fontSize: "0.75vw" }}>
                        <LangContext.Consumer>
                          {(i18n) => i18n.a.addLocation}
                        </LangContext.Consumer>
                      </span>
                    </Button>
                  ) : (
                    ""
                  )}
                </Col>
                <Col xs={4} sm={4} md={4} lg={3} xl={3} xxl={3} align="center">
                  <Button block className="button-drawer-popup">
                    <a
                      href={`http://www.google.com/maps?layer=c&cbll=${this.props.selectedVehicle.lat},${this.props.selectedVehicle.lng}`}
                      target="_blank"
                    >
                      <span style={{ fontSize: "0.75vw" }}>
                        Google Street View
                      </span>
                    </a>
                  </Button>
                </Col>
                {this.props.selectedVehicle != "" &&
                this.props.selectedVehicle.device_code.substring(0, 4) ==
                  "MDVR" ? (
                  <Col
                    xs={4}
                    sm={4}
                    md={4}
                    lg={3}
                    xl={3}
                    xxl={3}
                    align="center"
                  >
                    <Button block type="primary" onClick={this.handleMDVRMODAL}>
                      <span style={{ fontSize: "0.75vw" }}>
                        MDVR Live Stream
                      </span>
                    </Button>
                  </Col>
                ) : (
                  ""
                )}
                {(this.props.selectedVehicle != "" &&
                  this.props.selectedVehicle.device_code.substring(0, 3) ==
                    "J40") ||
                this.props.selectedVehicle.device_code.substring(0, 3) ==
                  "HNT" ||
                this.props.selectedVehicle.device_code.substring(0, 3) ==
                  "ISU" ? (
                  <Col
                    xs={4}
                    sm={4}
                    md={4}
                    lg={3}
                    xl={3}
                    xxl={3}
                    align="center"
                  >
                    <Button
                      block
                      type="primary"
                      onClick={this.handleWatchVideoRealTime}
                    >
                      <span style={{ fontSize: "0.8vw" }}>
                        <LangContext.Consumer>
                          {(i18n) => i18n.w.watchVideoRealTime}
                        </LangContext.Consumer>
                      </span>
                    </Button>
                  </Col>
                ) : (
                  ""
                )}
                {this.props.selectedVehicle != "" &&
                this.props.selectedVehicle.device_code.substring(0, 3) ==
                  "ESP" ? (
                  <Col
                    xs={4}
                    sm={4}
                    md={4}
                    lg={3}
                    xl={3}
                    xxl={3}
                    align="center"
                  >
                    <Button block type="primary" onClick={this.handleSnapShot}>
                      <span style={{ fontSize: "0.75vw" }}>SNAPSHOT</span>
                    </Button>
                  </Col>
                ) : (
                  ""
                )}

                <Col xs={4} sm={4} md={4} lg={3} xl={3} xxl={3} align="center">
                  <Button block type="primary" onClick={openReplay}>
                    <span style={{ fontSize: "0.75vw" }}>
                      {" "}
                      <LangContext.Consumer>
                        {(i18n) => i18n.r.replay}
                      </LangContext.Consumer>
                    </span>
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <ModalMdvr
            visibleMDVR={this.state.visibleMDVR}
            onClose={this.onCloseMdvr}
            modalData={this.state.modalData}
            jsession={this.props.jsessionid}
            vehList={this.state.vehList}
            platenoList={this.state.platenoList}
            numberOfChanel={this.state.numberOfChanel}
          />
          <OfflineModal
            visible={this.state.offlineModal}
            hideModal={this.hideOfflineModal}
          />
          <SnapShot
            selectedVehicle={this.props.selectedVehicle}
            snapShotModal={this.state.snapShotModal}
            onClose={this.onCloseSnapShot}
          />
        </Drawer>
      </div>
    );
  }
}

export default PopupDrawerNew;
