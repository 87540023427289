import React, { useCallback, useMemo, useState } from "react";
import escapeHtml from "escape-html";
import { jsx } from "slate-hyperscript";
import {
  Editable,
  withReact,
  useSlate,
  Slate,
  useSelected,
  useFocused,
  useSlateStatic,
  ReactEditor,
} from "slate-react";
import {
  Editor,
  Transforms,
  createEditor,
  Element as SlateElement,
  Text,
} from "slate";
import { withHistory } from "slate-history";
import { Button, IconToolbar, Toolbar } from "./Elements";
import { Divider, Row, Col, Icon } from "antd";
import { debounce } from "debounce";

const LIST_TYPES = ["numbered-list", "bulleted-list"];

const RichText = (props) => {
  const html = props.description ? props.description : "<p></p>";
  const document = new DOMParser().parseFromString(html, "text/html");
  const [value, setValue] = useState(deserialize(document.body));
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);

  let fontColor = "";
  if (props.disabled) {
    fontColor = "rgba(0, 0, 0, 0.25)";
  }

  const handleChange = debounce((newValue) => {
    setValue(newValue);
    props.setDescription(serialize({ children: newValue }));
  }, 300);

  return (
    <Slate
      editor={editor}
      value={value}
      onChange={(newValue) => {
        console.log("Editor state:", JSON.stringify(newValue, null, 2));
        handleChange(newValue);
      }}
    >
      <div className={props.disabled ? "toolbar-readonly" : ""}>
        <Toolbar>
          <MarkButton format="bold" icon="format_bold" />
          <MarkButton format="italic" icon="format_italic" />
          <MarkButton format="underline" icon="format_underlined" />
          <BlockButton format="heading-one" icon="looks_one" />
          <BlockButton format="heading-two" icon="looks_two" />
          <BlockButton format="heading-three" icon="looks_3" />
          <BlockButton format="numbered-list" icon="format_list_numbered" />
          <BlockButton format="bulleted-list" icon="format_list_bulleted" />
          <AlignButton format="left" icon={<Icon type="align-left" />} />
          <AlignButton format="center" icon={<Icon type="align-center" />} />
          <AlignButton format="right" icon={<Icon type="align-right" />} />
          <ImageButton />
        </Toolbar>
        <Divider style={{ padding: 0, margin: 0 }} />
      </div>

      <Editable
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        spellCheck
        autoFocus
        readOnly={props.disabled}
        style={{
          color: fontColor,
          margin: "10px",
          minHeight: "400px", // ความสูงขั้นต่ำ 400px
          height: "auto", // ปรับความสูงตามเนื้อหา
          overflowY: "auto",
        }}
      />
    </Slate>
  );
};

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type),
    split: true,
  });
  const newProperties = {
    type: isActive ? "paragraph" : isList ? "list-item" : format,
  };
  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const toggleAlign = (editor, format) => {
  const isActive = isAlignActive(editor, format);
  const newProperties = {
    alignment: isActive ? "left" : format,
  };
  Transforms.setNodes(editor, newProperties);
};

const isBlockActive = (editor, format) => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
    })
  );

  return !!match;
};

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  console.log("isMarkActive", marks);

  return marks ? marks[format] === true : false;
};

const isAlignActive = (editor, format) => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: selection,
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n.alignment === format,
    })
  );

  return !!match;
};

const Element = ({ attributes, children, element }) => {
  const style = element.alignment ? { textAlign: element.alignment } : {};
  const selected = useSelected();
  const focused = useFocused();
  const editor = useSlateStatic();
  const path = ReactEditor.findPath(editor, element);
  switch (element.type) {
    case "bulleted-list":
      return (
        <ul {...attributes} style={style}>
          {children}
        </ul>
      );
    case "heading-one":
      return (
        <h1
          {...attributes}
          style={{ textAlign: element.alignment, fontSize: 32 }}
        >
          {children}
        </h1>
      );
    case "heading-two":
      return (
        <h2
          {...attributes}
          style={{ textAlign: element.alignment, fontSize: 24 }}
        >
          {children}
        </h2>
      );
    case "heading-three":
      return (
        <h3
          {...attributes}
          style={{ textAlign: element.alignment, fontSize: 16 }}
        >
          {children}
        </h3>
      );

    case "list-item":
      return (
        <li {...attributes} style={style}>
          {children}
        </li>
      );
    case "numbered-list":
      return (
        <ol {...attributes} style={style}>
          {children}
        </ol>
      );
    case "image":
      return (
        <>
          <div
            {...attributes}
            style={{
              display: "flex",
              justifyContent: element.alignment || "center",
              marginBottom: 10,
            }}
          >
            <img
              className="slate-editor-img"
              src={element.url}
              alt=""
              style={{
                width: element.width || "100%",
                height: element.height || "auto",
              }}
              draggable="true"
            />
          </div>
        </>
      );

    default:
      return (
        <p {...attributes} style={style}>
          {children}
        </p>
      );
  }
};

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

const BlockButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <Button
      active={isBlockActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
      style={{ marginRight: 5 }}
    >
      <IconToolbar>{icon}</IconToolbar>
    </Button>
  );
};

const MarkButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
      style={{ marginRight: 5 }}
    >
      <IconToolbar>{icon}</IconToolbar>
    </Button>
  );
};

const AlignButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <Button
      active={isAlignActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleAlign(editor, format);
      }}
      style={{ marginRight: 5 }}
    >
      <IconToolbar>{icon}</IconToolbar>
    </Button>
  );
};

const serialize = (node) => {
  if (Text.isText(node)) {
    let string = escapeHtml(node.text);
    if (node.bold) {
      string = `<strong>${string}</strong>`;
    }

    if (node.italic) {
      string = `<em>${string}</em>`;
    }

    if (node.underline) {
      string = `<u>${string}</u>`;
    }

    return string;
  }

  const children = node.children.map((n) => serialize(n)).join("");
  const alignmentStyle = node.alignment
    ? ` style="text-align:${node.alignment};"`
    : "";

  let list = "";
  switch (node.type) {
    case "paragraph":
      return `<p${alignmentStyle}>${children}</p>`;
    case "numbered-list":
      node.children
        .map((n) => serialize(n))
        .map((a) => (list += `<li>${a}</li>`));
      return `<ol${alignmentStyle}>${list}</ol>`;
    case "heading-one":
      return `<h1${alignmentStyle}>${children}</h1>`;
    case "heading-two":
      return `<h2${alignmentStyle}>${children}</h2>`;
    case "heading-three":
      return `<h3${alignmentStyle}>${children}</h3>`;
    case "bulleted-list":
      node.children
        .map((n) => serialize(n))
        .map((a) => (list += `<li>${a}</li>`));
      return `<ul${alignmentStyle}>${list}</ul>`;
    case "image":
      return `<img class="slate-editor-img" src="${escapeHtml(
        node.url
      )}" style="width:${node.width || "100%"}; height:${node.height ||
        "auto"}; text-align:"center"};" />`;
    case "link": // Fixed case for links
      return `<a href="${node.url}">${children}</a>`;
    default:
      return children;
  }
};

const deserialize = (el) => {
  if (el.nodeType === 3) {
    // If text node
    return el.textContent;
  } else if (el.nodeType !== 1) {
    // If not an element node
    return null;
  }

  let children = Array.from(el.childNodes).map(deserialize);

  if (children.length === 0) {
    children = [{ text: "" }];
  }

  // Extract text-align style if present
  const style = el.getAttribute("style");
  let alignment = null;
  if (style) {
    const styleObj = style.split(";").reduce((acc, rule) => {
      const [key, value] = rule.split(":").map((item) => item.trim());
      if (key && value) {
        acc[key] = value;
      }
      return acc;
    }, {});

    alignment = styleObj["text-align"] || null;
  }

  switch (el.nodeName) {
    case "BODY":
      return jsx("fragment", {}, children);
    case "BR":
      return "\n";
    case "STRONG":
      return jsx("text", { bold: true }, children);
    case "P":
      return jsx("element", { type: "paragraph", alignment }, children);
    case "OL":
      return jsx("element", { type: "numbered-list", alignment }, [
        children.map((x) => jsx("element", { type: "list-item" }, [x])),
      ]);
    case "EM":
      return jsx("text", { italic: true }, children);
    case "U":
      return jsx("text", { underline: true }, children);
    case "H1":
      return jsx("element", { type: "heading-one", alignment }, children);
    case "H2":
      return jsx("element", { type: "heading-two", alignment }, children);
    case "H3":
      return jsx("element", { type: "heading-three", alignment }, children);
    case "UL":
      return jsx("element", { type: "bulleted-list", alignment }, [
        children.map((x) => jsx("element", { type: "list-item" }, [x])),
      ]);
    case "IMG":
      return jsx(
        "element",
        {
          type: "image",
          url: el.getAttribute("src"),
          width: el.style.width || "100%",
          height: el.style.height || "auto",
          alignment: "center",
        },
        [{ text: "" }] // Add a blank text node for proper rendering
      );
    case "A":
      return jsx(
        "text",
        { type: "link", url: el.getAttribute("href") },
        children
      );

    default:
      return el.textContent;
  }
};

const insertImage = (editor, url) => {
  const imageNode = {
    type: "image",
    url,
    width: "100%",
    height: "auto",
    children: [{ text: "" }], // Ensure there's always a text node
  };

  // Insert the image node
  Transforms.insertNodes(editor, imageNode);

  // Add a paragraph node after the image to continue typing
  const paragraphNode = { type: "paragraph", children: [{ text: "" }] };
  Transforms.insertNodes(editor, paragraphNode);

  // Move the selection to the new paragraph
  Transforms.move(editor);
};

const ImageButton = () => {
  const editor = useSlate();

  const handleUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (!file.type.startsWith("image/")) {
        alert("Please upload a valid image.");
        return;
      }
      if (file.size > 5 * 1024 * 1024) {
        // Limit size to 5MB
        alert("Image size must be less than 5MB.");
        return;
      }

      const reader = new FileReader();
      reader.onload = () => {
        const url = reader.result;
        insertImage(editor, url);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Button
      onMouseDown={(event) => {
        event.preventDefault();
        document.getElementById("fileUpload").click();
      }}
      style={{ marginRight: 5 }}
    >
      <IconToolbar>image</IconToolbar>
      <input
        id="fileUpload"
        type="file"
        style={{ display: "none" }}
        accept="image/*"
        onChange={handleUpload}
      />
    </Button>
  );
};

export default RichText;

