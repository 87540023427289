export const dataSource = [
  {
    name: "monitoring",
    label: (i18n) => i18n.m.monitoring,
    children: [
      {
        name: "tracking_dashboard",
        path: "/tracking",
        matching_name: "tracking_dashboard",
        label: (i18n) => i18n.t.tracking,
      },
      {
        name: "replay",
        path: "/replay",
        matching_name: "replay",
        label: (i18n) => i18n.r.replay,
      },
      {
        name: "incident_dashboard",
        path: "/incident-dashboard",
        matching_name: "incident_dashboard",
        label: (i18n) => i18n.i.incident,
      },
      // {
      //   name: "live_stream",
      //   label: (i18n) => i18n.l.liveStream,
      //   children: [
      {
        name: "honeytoast_livestream",
        path: "/livestream",
        matching_name: "honeytoast_livestream",
        label: (i18n) => i18n.l.liveStream,
      },
      {
        name: "video_mdvr",
        path: "/video",
        matching_name: "video_mdvr",
        label: (i18n) => i18n.v.video,
      },
      {
        name: "video_mdvr_special",
        path: "/videospecial",
        matching_name: "video_mdvr_special",
        label: (i18n) => i18n.v.videospecial,
      },
      //   ],
      // },
      // {
      // name: "video_download",
      // label: (i18n) => i18n.v.videoDownload,
      // children: [
      {
        name: "download_video_honeytoast",
        path: "/honeytoastdownloadfile",
        matching_name: "download_video_honeytoast",
        label: (i18n) => i18n.h.honeytoastdownloadfile,
      },
      {
        name: "download_video_mdvr",
        path: "/mdvrdownloadfile",
        matching_name: "download_video_mdvr",
        label: (i18n) => i18n.d.mdvrdownloadfile,
      },
      {
        name: "playback_video_honeytoast",
        path: "/honeytoastplayback",
        matching_name: "playback_video_honeytoast",
        label: (i18n) => i18n.h.honeytoastplayback,
      },
      //   ],
      // },

      {
        name: "boat_dashboard",
        path: "/boat_delivery",
        matching_name: "boat_dashboard",
        label: (i18n) => i18n.b.boatDeliveryDashboard,
        icon: "filter",
      },
      {
        name: "dash_camera_dashboard",
        path: "/dash_cameras",
        matching_name: "dash_camera_dashboard",
        label: (i18n) => i18n.d.dashCamera,
      },
      {
        name: "booking_overview_dashboard",
        path: "/booking_overview",
        matching_name: "booking_overview_dashboard",
        label: (i18n) => i18n.b.bookingOverview,
      },
      {
        name: "cashing_overview_dashboard",
        path: "/cashing_overview",
        matching_name: "cashing_overview_dahsboar",
        label: (i18n) => i18n.e.earningOverview,
      },

      //   ],
      // },
      {
        name: "cpf_dashboard",
        path: "/cpf-dashboard",
        matching_name: "cpfdashboard",
        label: (i18n) => i18n.c.cpfDashboard,
      },
    ],
  },

  {
    name: "dashboard",
    label: (i18n) => i18n.d.dashboard,
    children: [
      {
        name: "concrete_dashboard",
        path: "/concrete_delivery",
        matching_name: "concrete_dashboard",
        label: (i18n) => i18n.c.concreteDeliveryDashboard,
      },
      {
        name: "multi_drop_dashboard",
        path: "/multi_drop",
        matching_name: "multi_drop_dashboard",
        label: "Multi Drop",
      },
      {
        name: "alert_alarm_dashboard",
        path: "/alert_alarm",
        matching_name: "alert_alarm_dashboard",
        label: (i18n) => i18n.a.alertAlarm,
      },
      {
        name: "event_map_dashboard",
        path: "/event_map",
        matching_name: "event_map_dashboard",
        label: (i18n) => i18n.e.eventMap,
      },
      {
        name: "skic_inhouse_dashboard",
        path: "/skic-inhouse",
        matching_name: "skic-inhouse",
        label: "แดชบอร์ด Skic inhouse logistic",
      },
      {
        name: "driver_dashboard",
        path: "/driver-dashboard",
        matching_name: "driver-dashboard",
        label: (i18n) => i18n.d.driverDashboard,
      },
      {
        name: "camera_status_dashboard",
        path: "/camera-status-dashboard",
        matching_name: "cameraStatusDashboard",
        label: (i18n) => i18n.c.cameraStatusDashboard,
      },
      {
        name: "monitor_gps_dashboard",
        path: "/monitor-gps",
        matching_name: "monitorGPS",
        label: (i18n) => i18n.m.monitorGPS,
      },
      {
        name: "dashboardAdasDms",
        path: "/dashboard-adas",
        matching_name: "dashboardAdasDms",
        label: (i18n) => i18n.d.dashboardAdasDmsMenu,
      },
    ],
  },
  {
    name: "booking_pool",
    label: (i18n) => i18n.b.bookingPool,
    children: [
      {
        name: "booking_pool",
        path: "/center_pool",
        matching_name: "booking_pool",
        label: (i18n) => i18n.b.booking,
      },
      {
        name: "finished_pool",
        path: "/finished_pool",
        matching_name: "finished_pool",
        label: (i18n) => i18n.f.finished,
      },
      {
        name: "cancelled_pool",
        path: "/cancelled_pool",
        matching_name: "cancelled_pool",
        label: (i18n) => i18n.c.cancelled,
      },
    ],
  },
  {
    name: "report",
    label: (i18n) => i18n.r.report,
    children: [
      {
        name: "schedulereport",
        path: "/schedulereport",
        matching_name: "schedulereport",
        label: (i18n) => i18n.s.schedulereport,
      },
      {
        name: "graph_sensor",
        path: "/graphsensors",
        matching_name: "graph_sensor",
        label: (i18n) => i18n.g.graphsonsors,
      },
      {
        name: "performancelog",
        path: "/performancelog",
        matching_name: "performancelog",
        label: (i18n) => i18n.p.performanceLog,
      },

      // {
      //   name: "behavior_report_menu",
      //   label: (i18n) => i18n.b.behavior_report_menu,
      //   children: [
      {
        name: "report_alarmalertbyplateno",
        path: "/report/alarmalertbyplateno",
        matching_name: "report_alarmalertbyplateno",
        label: (i18n) =>
          i18n.a.alarmAndAlertReportClassifiedByDailyCarRegistration,
      },
      {
        name: "report_alarmalertbydriver",
        path: "/report/alarmalertbydriver",
        matching_name: "report_alarmalertbydriver",
        label: (i18n) => i18n.d.DailyAlarmAndAlertReportByDriver,
      },
      {
        name: "report_alarmalertfrequencybydriver",
        path: "/report/alarmalertfrequencybydriver",
        matching_name: "report_alarmalertfrequencybydriver",
        label: (i18n) => i18n.r.reportAlarmFrequencyClassifiedByDailyDriver,
      },
      {
        name: "report_alarmalertbydurationtime",
        path: "/report/alarmalertbydurationtime",
        matching_name: "report_alarmalertbydurationtime",
        label: (i18n) =>
          i18n.t.top5AlertReportsOfEachTypeDividedByMonthlyTimePeriod,
      },
      {
        name: "report_drivingbehavior",
        path: "/report/drivingbehavior",
        matching_name: "report_drivingbehavior",
        label: (i18n) => i18n.d.dailyDrivingBehaviorReport,
      },
      {
        name: "report_drivinglicensescanbehavior",
        path: "/report/drivinglicensescanbehavior",
        matching_name: "report_drivinglicensescanbehavior",
        label: (i18n) => i18n.r.reportCardSwipeBehaviorBeforeDrivingDaily,
      },
      {
        name: "report_alarmalertbyplatenoonyear",
        path: "/report/alarmalertbyplatenoonyear",
        matching_name: "report_alarmalertbyplatenoonyear",
        label: (i18n) =>
          i18n.a.alarmAndAlertReportClassifiedByYearlyCarRegistration,
      },
      {
        name: "report_separatevehiclestatus",
        path: "/report/separatevehiclestatus",
        matching_name: "report_separatevehiclestatus",
        label: (i18n) => i18n.d.dailyCarStatusReport,
      },
      {
        name: "report_alertdriverlicense",
        path: "/report/alertdriverlicense",
        matching_name: "report_alertdriverlicense",
        label: (i18n) => i18n.t.theDriverIsIicenseReportHasExpired,
      },
      {
        name: "report_parkingareaoutside",
        path: "/report/parkingareaoutside",
        matching_name: "report_parkingareaoutside",
        label: (i18n) => i18n.r.reportSummarizingTheNumberOfOffSiteParking,
      },
      {
        name: "report_drivinglog",
        path: "/report/drivinglog",
        matching_name: "report_drivinglog",
        label: (i18n) => i18n.d.DrivinglogReport,
      },
      {
        name: "report_drivinglogspecial",
        path: "/report/drivinglogspecial",
        matching_name: "report_drivinglogspecial",
        label: (i18n) => i18n.d.DrivinglogspecialReport,
      },
      {
        name: "report_summary_drivinglog",
        path: "/report/summarydrivinglog",
        matching_name: "report_summary_drivinglog",
        label: (i18n) => i18n.s.summaryDrivingLogReport,
      },
      {
        name: "report_speedoverlimitsummary",
        path: "/report/speedoverlimitsummary",
        matching_name: "report_speedoverlimitsummary",
        label: (i18n) => i18n.s.SpeedoverlimitsummaryReport,
      },
      {
        name: "report_summarykpi",
        path: "/report/summarykpi",
        matching_name: "report_summarykpi",
        label: (i18n) => i18n.s.SummaryKPIReport,
      },
      {
        name: "report_summarykpibydriver",
        path: "/report/summarykpibydriver",
        matching_name: "report_summarykpibydriver",
        label: (i18n) => i18n.s.SummaryKPIByDriverReport,
      },
      {
        name: "report_riskbehaviour",
        path: "/report/riskbehaviour",
        matching_name: "report_riskbehaviour",
        label: (i18n) => i18n.r.RiskbehaviourReport,
      },
      {
        name: "report_vehicleinarea",
        path: "/report/vehicleinarea",
        matching_name: "report_vehicleinarea",
        label: (i18n) => i18n.v.VehicleinareaReport,
      },
      {
        name: "report_inoutlocation",
        path: "/report/inoutlocation",
        matching_name: "report_inoutlocation",
        label: (i18n) => i18n.i.inoutlocationreport,
      },
      {
        name: "report_drivinglicensescanlog",
        path: "/report/drivinglicensescanlog",
        matching_name: "report_drivinglicensescanlog",
        label: (i18n) => i18n.d.DrivinglicensescanlogReport,
      },
      {
        name: "report_drivinglicenseexpire",
        path: "/report/drivinglicenseexpire",
        matching_name: "report_drivinglicenseexpire",
        label: (i18n) => i18n.d.DrivingLicenseExpireReport,
      },
      {
        name: "report_drivinghour",
        path: "/report/drivinghour",
        matching_name: "report_drivinghour",
        label: (i18n) => i18n.d.DrivinghourReport,
      },
      {
        name: "speedOverLimit",
        path: "/report/speedoverlimit",
        matching_name: "speedOverLimit",
        label: (i18n) => i18n.s.speedOverLimit,
      },
      {
        name: "report_drivingcontinuous",
        path: "/report/drivingcontinuous",
        matching_name: "report_drivingcontinuous",
        label: (i18n) => i18n.d.drivingcontinuous,
      },
      {
        name: "report_notinotswipecard",
        path: "/report/notinotswipecard",
        matching_name: "report_notinotswipecard",
        label: (i18n) => i18n.n.notinotswipecard,
      },
      {
        name: "report_incident",
        path: "/report/incident",
        matching_name: "report_incident",
        label: (i18n) => i18n.i.incidentReport,
      },
      {
        name: "report_flow",
        path: "/report/flow",
        matching_name: "report_flow",
        label: (i18n) => i18n.r.reportFlow,
      },
      {
        name: "report_adas_event",
        path: "/report/adasevent",
        matching_name: "report_adas_event",
        label: (i18n) => i18n.a.AdasEventReport,
      },
      //   ],
      // },

      // {
      //   name: "general_report_menu",
      //   label: (i18n) => i18n.g.general_report_menu,
      //   children: [
      {
        name: "report_truckcurrentlocation",
        path: "/report/truckcurrentlocation",
        matching_name: "report_truckcurrentlocation",
        label: (i18n) => i18n.t.TruckcurrentlocationReport,
      },
      {
        name: "report_driverinformation",
        path: "/report/driverinformation",
        matching_name: "report_driverinformation",
        label: (i18n) => i18n.d.DriverinformationReport,
      },
      {
        name: "report_numberofdaysworked",
        path: "/report/numberofdaysworked",
        matching_name: "report_numberofdaysworked",
        label: (i18n) => i18n.n.NumberofdaysworkedReport,
      },
      {
        name: "report_distfromlastmaintenance",
        path: "/report/distfromlastmaintenance",
        matching_name: "report_distfromlastmaintenance",
        label: (i18n) => i18n.d.DistfromlastmaintenanceReport,
      },
      {
        name: "report_maintenance",
        path: "/report/maintenance",
        matching_name: "report_maintenance",
        label: (i18n) => i18n.m.MaintenanceReport,
      },
      {
        name: "report_temperature",
        path: "/report/temperature",
        matching_name: "report_temperature",
        label: (i18n) => i18n.t.TemperatureReport,
      },
      {
        name: "report_drivingdistance",
        path: "/report/drivingdistance",
        matching_name: "report_drivingdistance",
        label: (i18n) => i18n.d.DrivingdistanceReport,
      },
      {
        name: "report_event",
        path: "/report/event",
        matching_name: "report_event",
        label: (i18n) => i18n.e.EventReport,
      },
      {
        name: "report_truckusage",
        path: "/report/truckusage",
        matching_name: "report_truckusage",
        label: (i18n) => i18n.t.TruckusageReport,
      },
      {
        name: "report_truckusage_sjc_monthly",
        path: "/report/monthlytruckusages",
        matching_name: "report_truckusage_sjc_monthly",
        label: (i18n) => i18n.t.TruckusageSJCMonthlyReport,
      },
      {
        name: "report_parking",
        path: "/report/parking",
        matching_name: "report_parking",
        label: (i18n) => i18n.p.ParkingReport,
      },
      {
        name: "report_comparevehiclescco",
        path: "/report/comparevehiclescco",
        matching_name: "report_comparevehiclescco",
        label: (i18n) => i18n.c.CompareVehicleSccoReport,
      },
      {
        name: "report_drivingoverlimit",
        path: "/report/drivingoverlimit",
        matching_name: "report_drivingoverlimit",
        label: (i18n) => i18n.d.DrivingoverlimitReport,
      },
      {
        name: "report_dozeoffrisk",
        path: "/report/dozeoffrisk",
        matching_name: "report_dozeoffrisk",
        label: (i18n) => i18n.d.DozeoffriskReport,
      },
      {
        name: "report_gpsstatus",
        path: "/report/gpsstatus",
        matching_name: "report_gpsstatus",
        label: (i18n) => i18n.g.GpsstatusReport,
      },
      {
        name: "report_tripbyengineoffon",
        path: "/report/tripbyengineoffon",
        matching_name: "report_tripbyengineoffon",
        label: (i18n) => i18n.t.TripbyengineoffonReport,
      },
      {
        name: "userlogonhistory",
        path: "/report/userlogonhistory",
        matching_name: "userlogonhistory",
        label: (i18n) => i18n.u.userLogonReport,
      },
      {
        name: "report_dailyiconsiam",
        path: "/report/dailyiconsiam",
        matching_name: "report_dailyiconsiam",
        label: (i18n) => i18n.d.DailyiconsiamReport,
      },
      {
        name: "report_summaryiconsiam",
        path: "/report/summaryiconsiam",
        matching_name: "report_summaryiconsiam",
        label: (i18n) => i18n.s.SummaryiconsiamReport,
      },
      {
        name: "report_fuelremainrate",
        path: "/report/fuelremainrate",
        matching_name: "report_fuelremainrate",
        label: (i18n) => i18n.f.fuelremainrateReport,
      },
      {
        name: "report_fuelnotify",
        path: "/report/fuelnotify",
        matching_name: "report_fuelnotify",
        label: (i18n) => i18n.f.fuelNotifyReport,
      },
      {
        name: "report_summarylinenotification",
        path: "/report/summarylinenotification",
        matching_name: "report_summarylinenotification",
        label: (i18n) => i18n.r.report_summarylinenotification,
      },
      {
        name: "report_driverincome",
        path: "/report/driverincome",
        matching_name: "report_driverincome",
        label: (i18n) => i18n.d.driverincome,
      },
      //   ],
      // },

      // {
      //   name: "efficiency_report_menu",
      //   label: (i18n) => i18n.e.efficiency_report_menu,
      //   children: [
      {
        name: "report_inputactivity",
        path: "/report/inputactivity",
        matching_name: "report_inputactivity",
        label: (i18n) => i18n.i.InputactivityReport,
      },
      {
        name: "report_trucknotuse",
        path: "/report/trucknotuse",
        matching_name: "report_trucknotuse",
        label: (i18n) => i18n.t.TrucknotuseReport,
      },
      {
        name: "report_truckengineoff",
        path: "/report/truckengineoff",
        matching_name: "report_truckengineoff",
        label: (i18n) => i18n.t.TruckengineoffReport,
      },
      {
        name: "report_truckengineon",
        path: "/report/truckengineon",
        matching_name: "report_truckengineon",
        label: (i18n) => i18n.t.TruckengineonReport,
      },
      {
        name: "report_velocitysummary",
        path: "/report/velocitysummary",
        matching_name: "report_velocitysummary",
        label: (i18n) => i18n.v.VelocitysummaryReport,
      },
      {
        name: "report_vehicleperformanceconclude",
        path: "/report/vehicleperformanceconclude",
        matching_name: "report_vehicleperformanceconclude",
        label: (i18n) => i18n.v.VehicleperformanceconcludeReport,
      },
      {
        name: "report_gpslostcontact",
        path: "/report/gpslostcontact",
        matching_name: "report_gpslostcontact",
        label: (i18n) => i18n.r.report_gpsLostContact,
      },
      {
        name: "report_video_snapshot",
        path: "/report/video-snapshot-report",
        matching_name: "report_video_snapshot",
        label: (i18n) => i18n.v.videoSnapshotReport,
      },
      //   ],
      // },

      // {
      //   name: "trip_report_menu",
      //   label: (i18n) => i18n.t.trip_report_menu,
      //   children: [
      {
        name: "report_tripsumbylocation",
        path: "/report/tripsumbylocation",
        matching_name: "report_tripsumbylocation",
        label: (i18n) => i18n.t.TripsumbylocationReport,
      },
      {
        name: "report_tripsummary",
        path: "/report/tripsummary",
        matching_name: "report_tripsummary",
        label: (i18n) => i18n.t.TripsummaryReport,
      },
      {
        name: "report_daily",
        path: "/report/daily",
        matching_name: "report_daily",
        label: (i18n) => i18n.d.DailyReport,
      },
      {
        name: "report_tripsumbyveh",
        path: "/report/tripsumbyveh",
        matching_name: "report_tripsumbyveh",
        label: (i18n) => i18n.t.TripsumbyvehReport,
      },
      {
        name: "report_triptemperature",
        path: "/report/triptemperature",
        matching_name: "report_triptemperature",
        label: (i18n) => i18n.t.TriptemperatureReport,
      },
      {
        name: "report_delivery",
        path: "/report/delivery",
        matching_name: "report_delivery",
        label: (i18n) => i18n.d.DeliveryReport,
      },
      {
        name: "report_boatdelivery",
        path: "/report/boatdelivery",
        matching_name: "report_boatdelivery",
        label: (i18n) => i18n.b.boatDeliveryReport,
      },
      {
        name: "report_pickupanddropoffemployee",
        path: "/report/pickup-dropoff-employee",
        matching_name: "report_pickupanddropoffemployee",
        label: (i18n) => i18n.p.pickupAndDropoffEmployee,
      },
      {
        name: "report_vehicleentryalert",
        path: "/report/vehicle-entry-alert",
        matching_name: "report_vehicleentryalert",
        label: (i18n) => i18n.v.vehicleEntryAlert,
      },
      //   ],
      // },

      // {
      //   name: "energy_report_menu",
      //   label: (i18n) => i18n.e.energy_report_menu,
      //   children: [
      {
        name: "report_refuel",
        path: "/report/refuel",
        matching_name: "report_refuel",
        label: (i18n) => i18n.r.RefuelReport,
      },
      {
        name: "report_fuelfull",
        path: "/report/fuelfull",
        matching_name: "report_fuelfull",
        label: (i18n) => i18n.f.fuelFull,
      },
      {
        name: "report_withdrawfuelcredit",
        path: "/report/withdrawfuelcredit",
        matching_name: "report_withdrawfuelcredit",
        label: (i18n) => i18n.r.reportWithdrawfuelcredit,
      },
      {
        name: "report_depositfuelcredit",
        path: "/report/depositfuelcredit",
        matching_name: "report_depositfuelcredit",
        label: (i18n) => i18n.r.reportDepositfuelcredit,
      },
      //   ],
      // },

      // {
      //   name: "maintenancedevice_report_menu",
      //   label: (i18n) => i18n.m.maintenancedevice_report_menu,
      //   children: [
      {
        name: "report_maintenancedeviceconclude",
        path: "/report/maintenancedeviceconclude",
        matching_name: "report_maintenancedeviceconclude",
        label: (i18n) => i18n.m.MaintenancedeviceconcludeReport,
      },
      {
        name: "report_vehicleinformationdlt",
        path: "/report/vehicleinformationdlt",
        matching_name: "report_vehicleinformationdlt",
        label: (i18n) => i18n.v.VehicleinformationdltReport,
      },
      //   ],
      // },
      // {
      //   name: "insure_report_menu",
      //   label: (i18n) => i18n.i.insure_report_menu,
      //   children: [
      {
        name: "report_insurance",
        path: "/report/insurance",
        matching_name: "report_insurance",
        label: (i18n) => i18n.i.InsuranceReport,
      },
      //   ],
      // },
      // {
      //   name: "taxi",
      //   label: "Taxi",
      //   children: [
      {
        name: "report_taxifare",
        path: "/report/taxifare",
        matching_name: "report_taxifare",
        label: (i18n) => i18n.r.report_taxifare,
      },

      {
        name: "report_taxisos",
        path: "/report/taxisos",
        matching_name: "report_taxisos",
        label: (i18n) => i18n.r.report_taxisos,
      },
      //   ],
      // },
    ],
  },

  {
    name: "master_data",
    label: (i18n) => i18n.m.masterData,
    children: [
      //   {
      // name: "vehicle_and_driver",
      // label: (i18n) => i18n.v.vehicleAndDriver,
      // children: [
      {
        name: "vehicle",
        path: "/vehicle",
        matching_name: "vehicle",
        label: (i18n) => i18n.v.vehicle,
      },
      {
        name: "vehiclegroup",
        path: "/vehiclegroup",
        matching_name: "vehiclegroup",
        label: (i18n) => i18n.v.vehicleGroup,
      },
      {
        name: "deviceuser",
        path: "/deviceuser",
        matching_name: "deviceuser",
        label: (i18n) => i18n.c.chauffeur,
      },
      {
        name: "parttype",
        path: "/parttype",
        matching_name: "parttype",
        label: (i18n) => i18n.p.partType,
      },
      {
        name: "partgroup",
        path: "/partgroup",
        matching_name: "partgroup",
        label: (i18n) => i18n.p.partgroup,
      },
      {
        name: "refuel",
        path: "/refuel",
        matching_name: "refuel",
        label: (i18n) => i18n.r.reFuelTitle,
      },
      {
        name: "maintenance",
        path: "/maintenance",
        matching_name: "maintenance",
        label: (i18n) => i18n.m.maintenance,
      },
      //   ],
      // },
      // {
      //   name: "location_and_route",
      //   label: (i18n) => i18n.l.locationAndRoute,
      //   children: [
      {
        name: "location",
        path: "/location",
        matching_name: "location",
        label: (i18n) => i18n.l.Location,
      },
      {
        name: "locationgroup",
        path: "/locationgroup",
        matching_name: "locationgroup",
        label: (i18n) => i18n.l.locationGroup,
      },
      {
        name: "location-type",
        path: "/location-type",
        matching_name: "location-type",
        label: (i18n) => i18n.l.location_type,
      },
      {
        name: "nearbylocation",
        path: "/nearbylocation",
        matching_name: "nearbylocation",
        label: (i18n) => i18n.n.nearByLocation,
      },
      {
        name: "zone",
        path: "/zone",
        matching_name: "zone",
        label: (i18n) => i18n.z.zone,
      },
      {
        name: "route",
        path: "/route",
        matching_name: "route",
        label: (i18n) => i18n.r.route,
      },
      {
        name: "route_iconsiam",
        path: "/route-iconsiam",
        matching_name: "route_iconsiam",
        label: (i18n) => i18n.r.route_iconsiam,
      },
      {
        name: "trip_duration",
        path: "/trip-duration",
        matching_name: "trip_duration",
        label: (i18n) => i18n.t.trip_duration_management,
      },
      //   ],
      // },

      {
        name: "customer",
        path: "/customer",
        matching_name: "customer",
        label: (i18n) => i18n.c.customer,
      },
      {
        name: "pull_vehicle_sink",
        path: "/pullvehiclesink",
        matching_name: "pull_vehicle_sink",
        label: (i18n) => i18n.p.pullvehiclesink,
      },
      {
        name: "sendemail",
        path: "/verifyemail",
        matching_name: "sendemail",
        label: (i18n) => i18n.a.accountVerification,
      },
      {
        name: "uom",
        path: "/uom",
        matching_name: "uom",
        label: (i18n) => i18n.u.uom,
      },
      {
        name: "product",
        path: "/product",
        matching_name: "product",
        label: (i18n) => i18n.p.product,
      },
    ],
  },

  {
    name: "management_menu",
    label: (i18n) => i18n.m.management_menu,
    children: [
      {
        name: "user",
        path: "/user",
        matching_name: "user",
        label: (i18n) => i18n.u.user,
      },
      {
        name: "usergroup",
        path: "/usergroup",
        matching_name: "usergroup",
        label: (i18n) => i18n.u.userGroup,
      },
      {
        name: "datavisibility",
        path: "/datavisibility",
        matching_name: "datavisibility",
        label: (i18n) => i18n.d.dataVisibility,
      },
      {
        name: "permission",
        path: "/permission",
        matching_name: "permission",
        label: "Permission",
      },
      {
        name: "partner",
        path: "/partner",
        matching_name: "partner",
        label: (i18n) => i18n.p.partnerCar,
      },
      {
        name: "vehicle_sharing",
        path: "/vehicle_sharing",
        matching_name: "vehicle_sharing",
        label: (i18n) => i18n.v.vehicleSharing,
      },
      {
        name: "sharelocation",
        path: "/sharelocation",
        matching_name: "sharelocation",
        label: (i18n) => i18n.s.shareLocation,
      },
      {
        name: "gallery",
        path: "/gallery",
        matching_name: "gallery",
        label: (i18n) => i18n.g.gallery,
      },
      {
        name: "management_contactmanagement",
        path: "/management/contactmanagement",
        matching_name: "management_contactmanagement",
        label: (i18n) => i18n.c.Contactmanagement,
      },
      {
        name: "manageotp",
        path: "/manage-otp",
        matching_name: "manageotp",
        label: (i18n) => i18n.manageOTP.manageOTP,
      },
      {
        name: "managefuelrate",
        path: "/manage-fuelrate",
        matching_name: "managefuelrate",
        label: (i18n) => i18n.manageFuelRate.manageFuelRate,
      },
      {
        name: "manage_document2",
        path: "/manage-document2",
        matching_name: "manage_document2",
        label: (i18n) => i18n.i.insuranceAndTaxes,
      },
      {
        name: "managedepositfuelcredit",
        path: "/deposit-fuel-credit",
        matching_name: "managedepositfuelcredit",
        label: (i18n) => i18n.m.manageDepositFuelCredit,
      },
      {
        name: "managewithdrawfuelcredit",
        path: "/withdraw-fuel-credit",
        matching_name: "managewithdrawfuelcredit",
        label: (i18n) => i18n.m.manageWithdrawFuelCredit,
      },
      {
        name: "fuelcredit-setting",
        path: "/fuelcredit-setting",
        matching_name: "fuelcredit-setting",
        label: (i18n) => i18n.orc.configFuelcredit,
      },
      {
        name: "outofcontact",
        path: "/outofcontact",
        matching_name: "outofcontact",
        label: (i18n) => i18n.o.outofcontact,
      },
      {
        name: "management_downloadcertificate",
        path: "/management/downloadcertificate",
        matching_name: "management_downloadcertificate",
        label: (i18n) => i18n.d.downloadcertificate,
      },
      {
        name: "temporarytracking",
        path: "/temporarytracking",
        matching_name: "temporarytracking",
        label: (i18n) => i18n.t.temporaryTracking,
      },
    ],
  },
  {
    name: "internal_operation",
    label: (i18n) => i18n.i.internalOperation,
    children: [
      // {
      //   name: "delivery_item",
      //   label: (i18n) => i18n.d.deliveryItem,
      //   children: [
      {
        name: "deliveryitem",
        path: "/deliveryitem",
        matching_name: "deliveryitem",
        label: (i18n) => i18n.d.deliveryItemPool,
      },
      {
        name: "deliveryitem_delete_history",
        path: "/deliveryitem_delete_history",
        matching_name: "deliveryitem_delete_history",
        label: (i18n) => i18n.d.deliveryItemDeleteHistoryPool,
      },
      //   ],
      // },

      // {
      //   name: "gps_certification",
      //   label: (i18n) => i18n.g.gpsCertification,
      //   children: [
      {
        name: "gps_certification_request",
        path: "/gps_certification_request",
        matching_name: "gps_certification_request",
        label: (i18n) => i18n.g.gpsCertRequest,
      },
      {
        name: "gps_certification_request_history",
        path: "/gps_certification_request_history",
        matching_name: "gps_certification_request_history",
        label: (i18n) => i18n.g.gpsCertRequestHistory,
      },
      //   ],
      // },

      // {
      //   name: "announcement",
      //   label: (i18n) => i18n.a.announcement,
      //   children: [
      {
        name: "announcement_broadcast",
        path: "/announcement_broadcast",
        matching_name: "announcement_broadcast",
        label: (i18n) => i18n.a.announcementBroadcast,
      },
      //   ],
      // },

      {
        name: "company",
        path: "/company",
        matching_name: "company",
        label: (i18n) => i18n.c.company,
      },
      {
        name: "create_frimware",
        path: "/firmware",
        matching_name: "create_frimware",
        label: (i18n) => i18n.c.createFrimware,
      },
      {
        name: "assign_frimware",
        path: "/assignfirmware",
        matching_name: "assign_frimware",
        label: (i18n) => i18n.a.assignFirmware,
      },
    ],
  },

  {
    name: "deliver",
    label: (i18n) => i18n.d.deliver,
    children: [
      {
        name: "trip",
        path: "/trip",
        matching_name: "trip",
        label: (i18n) => i18n.t.trip,
      },
      {
        name: "delivery_trip",
        path: "/deliverytrip",
        matching_name: "delivery_trip",
        label: (i18n) => i18n.d.deliverytrip,
      },
      {
        name: "route_master",
        path: "/routemaster",
        matching_name: "route_master",
        label: (i18n) => i18n.r.routemaster,
      },
    ],
  },

  {
    name: "help_menu",
    label: (i18n) => i18n.h.help_menu,
    children: [
      {
        name: "servicerepairdevice",
        path: "/servicerepairdevice",
        matching_name: "servicerepairdevice",
        label: (i18n) => i18n.s.Servicerepairdevice,
      },
      {
        name: "servicemovedevice",
        path: "/servicemovedevice",
        matching_name: "servicemovedevice",
        label: (i18n) => i18n.m.moveDevice,
      },
      {
        name: "manage_repairdevice",
        path: "/manage-repairdevice",
        matching_name: "manage_repairdevice",
        label: (i18n) => i18n.m.manageRepairDevice,
      },
      {
        name: "monitorSDCard",
        path: "/monitor-sdcard",
        matching_name: "monitorSDCard",
        label: (i18n) => i18n.m.monitorSDCard,
      },
    ],
  },

  {
    name: "setting",
    label: (i18n) => i18n.s.setting,
    children: [
      {
        name: "policy",
        path: "/policy",
        matching_name: "policy",
        label: (i18n) => i18n.p.policy,
      },
      {
        name: "calibratefuel",
        path: "/calibratefuel",
        matching_name: "calibratefuel",
        label: (i18n) => i18n.c.calibratefuel,
      },
      {
        name: "companysetting",
        path: "/companysetting",
        matching_name: "companysetting",
        label: (i18n) => i18n.c.companysetting,
      },
    ],
  },
];
