import React, { useState } from "react";
import FormNews from "./FormNews";
import { createNews } from "../fetchApi/apiClient";
import { Spin } from "antd";

const NewsCreate = () => {
  const [loading, setLoading] = useState(false);
  return (
    <Spin spinning={loading}>
      <FormNews
        initialValues={{
          titleName: "",
          description: "",
          cover: "",
          newsCatagory: "1",
          content: "",
          isPublish: false,
        }}
        readOnly={false}
        onSubmit={(val) => {
          console.log("onSubmit", val);
          setLoading(true);
          createNews(
            {
              topic: val.titleName,
              news_category_id: val.newsCatagory,
              description: val.description,
              cover: val.cover[0]["url"],
              detail: val.content,
              is_publish: val.isPublish,
            },
            (status, res) => {
              setLoading(false);
              window.alert("สร้างสำเร็จ");
              window.location.replace("/news-manuals");
            }
          );
        }}
      />
    </Spin>
  );
};

export default NewsCreate;
