import React, { useEffect, useState } from "react";
import { Table, Icon, Tooltip, Row, Col, Button, Spin } from "antd";
import LangContext, { i18n } from "modules/shared/context/langContext";
import { TableStyled } from "./StyleComponent";
import { ReactComponent as TrackingReport } from "../components/Icons/Tracking.svg";
import { ReactComponent as VideoRealTimeReport } from "../components/Icons/VideoRealTime.svg";
import { ReactComponent as Replay } from "../components/Icons/Replay.svg";
import { ReactComponent as DownloadVideo } from "../components/Icons/Download File.svg";
import { Link } from "react-router-dom";
import CustomMap from "./CustomMap";
import LocationInfoMarker from "../../../shared/components/map-assets/Marker/LocationInfoMarker";
import { eventDetail } from "../fetchAPI/apiClient";
import moment from "moment";

const TableTab = ({ filterObj, auth, setFilterObj }) => {
  const [defaultMapValue, setDefaultMapValue] = useState({
    center: {
      lat: 13.729852,
      lng: 100.559484,
    },
    zoom: 5,
  });
  const [map, setMap] = useState(null);
  const [maps, setMaps] = useState(null);
  const [mapAPI, setMapAPI] = useState({
    trafficLayer: null,
    locationLayer: null,
  });
  const [dataTable, setDataTable] = useState([]);
  const [locationLatLng, setLocationLatLng] = useState([]);
  const [isMapVisible, setIsMapVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [vehicleFilter, setVehicleFilter] = useState(null);
  useEffect(() => {
    if (!!filterObj.policyId) {
      setLoading(true);
      eventDetail(
        {
          topic_id: !!filterObj.policyId ? [filterObj.policyId] : [],
          topic_type: filterObj.type,
          vehicle_group_id: [],
          vehicle_id: !!filterObj.vehicleSelected
            ? filterObj.vehicleSelected
            : "",
          start_at: moment(filterObj.dateTime[0]).format("YYYY-MM-DD 00:00:00"),
          end_at: moment(filterObj.dateTime[1]).format("YYYY-MM-DD 23:59:59"),
          user_id: auth.profile.id.toString(),
          company_id: auth.profile.company_id.toString(),
        },
        // {
        //   topic_id: [1646, 1778, 1644],
        //   topic_type: "policy",
        //   vehicle_group_id: [],
        //   vehicle_id: !!filterObj.vehicleSelected
        //     ? filterObj.vehicleSelected.toString()
        //     : "",
        //   start_at: "2025-01-01 00:00:00",
        //   end_at: "2025-01-01 23:59:59",
        //   user_id: "175",
        //   company_id: "48",
        // },
        (status, response) => {
          setDataTable(response.data.data);
          setLoading(false);
        }
      );
    }
  }, [filterObj]);

  useEffect(() => {
    if (isMapVisible) {
      if (maps) {
        let bounds = new maps.LatLngBounds();
        let position = null;

        position = new maps.LatLng(
          locationLatLng[0].lat,
          locationLatLng[0].lng
        );
        bounds.extend(position);
        if (position !== null) {
          map.fitBounds(bounds);
          map.setZoom(10);
        }
      }
    }
  }, [maps, locationLatLng, isMapVisible]);

  const handleApiLoaded = (map, maps) => {
    if (typeof maps == "undefined" || maps == null) window.location.reload();
    setMap(map);
    setMaps(maps);
    setMapAPI({ ...mapAPI, trafficLayer: new maps.TrafficLayer() });
  };

  const handleSelectLocation = (lat, lng, plateNo) => {
    setLocationLatLng([
      {
        lat,
        lng,
        plateNo,
      },
    ]);
    if (map && maps) {
      let bounds = new maps.LatLngBounds();
      let position = null;

      position = new maps.LatLng(lat, lng);
      bounds.extend(position);
      if (position !== null) {
        map.fitBounds(bounds);
        map.setZoom(10);
      }
    }

    setIsMapVisible(true);
  };

  const onSearchByVehicleId = (record) => {
    setFilterObj((prev) => ({
      ...prev,
      vehicleSelected: record.vehicle_id.toString(),
    }));
  };

  const handleClose = () => {
    setIsMapVisible(false);
    setLocationLatLng([]); // Reset marker data
    setMap(null); // Reset map instance
    setMaps(null);
  };

  const baseColumns = [
    {
      title: "#",
      dataIndex: "number",
      key: "name",
      render: (text, record, index) => <label>{index + 1}</label>,
      width: 50,
      align: "center",
    },
    {
      title: (
        <LangContext.Consumer>
          {(i18n) => i18n.p.policyName}
        </LangContext.Consumer>
      ),
      dataIndex: "topic_name",
      width: 200,
    },
    // {
    //   title: (
    //     <LangContext.Consumer>
    //       {(i18n) => i18n.p.policyType}
    //     </LangContext.Consumer>
    //   ),
    //   width: 80,
    //   align: "center",
    //   dataIndex: "topic_type",
    //   render: (text, record) => <div>{text}</div>,
    // },
    // {
    //   title: (
    //     <LangContext.Consumer>
    //       {(i18n) => i18n.p.policySubType}
    //     </LangContext.Consumer>
    //   ),
    //   width: 100,
    //   align: "center",
    //   dataIndex: "topic_sub_type",
    //   render: (text, record) => (
    //     <LangContext.Consumer>
    //       {(i18n) => i18n.policy[convertSubType(text)]}
    //     </LangContext.Consumer>
    //   ),
    // },
    {
      title: (
        <>
          <LangContext.Consumer>
            {(i18n) => i18n.p.plateNo}
          </LangContext.Consumer>
          {" ("}
          <LangContext.Consumer>
            {(i18n) => i18n.v.vehicleCode}
          </LangContext.Consumer>
          {")"}
        </>
      ),
      width: 200,
      dataIndex: "plate_no",
      render: (text, record) => (
        <a
          onClick={() => {
            onSearchByVehicleId(record);
          }}
          style={{ cursor: "pointer" }}
        >
          <label style={{ cursor: "pointer" }}>{text}</label>
          <br />
          {" ("}
          <label style={{ cursor: "pointer" }}>{record.vehicle_code}</label>
          {")"}
        </a>
      ),
    },
    {
      title: (
        <>
          <label>ชื่อคนขับรถ:</label>
          <br />
          <label>ชื่อคนขับรถจากการรูดบัตร:</label>
          <br />
          <label>เลขบัตรประชาชน:</label>
        </>
      ),
      width: 180,
      dataIndex: "driver_name",
      render: (text, record) => (
        <div>
          <label>{!!text ? text : "ไม่พบชื่อคนขับ"}</label>
          <br />
          <label>
            {!!record.driver_scan_license_name
              ? record.driver_scan_license_name
              : "ไม่พบชื่อคนขับรถจากการรูดบัตร"}
          </label>
          <br />
          <label>
            {!!record.driver_scan_idcard
              ? record.driver_scan_idcard
              : "ไม่พบเลขบัตรประชาชน"}
          </label>
        </div>
      ),
    },
    {
      title: (
        <>
          <LangContext.Consumer>
            {(i18n) => i18n.s.startAt}
          </LangContext.Consumer>
        </>
      ),
      width: 150,
      dataIndex: "start_at",
      render: (text, record) => <div>{!!text ? text : "-"}</div>,
    },
    {
      title: (
        <>
          <LangContext.Consumer>{(i18n) => i18n.e.endAt}</LangContext.Consumer>
        </>
      ),
      width: 150,
      dataIndex: "end_at",
      render: (text, record) => <div>{!!text ? text : "-"}</div>,
    },
    {
      title: (
        <>
          <LangContext.Consumer>
            {(i18n) => i18n.p.position}
          </LangContext.Consumer>
        </>
      ),
      width: 110,
      align: "left",
      dataIndex: "lat",
      render: (text, record) => (
        <a
          onClick={() => {
            handleSelectLocation(record.lat, record.lng, record.plate_no);
          }}
        >
          {record.lat} <br />
          {`,${record.lng}`}
        </a>
      ),
    },
  ];

  const convertSubType = (val) => {
    switch (val) {
      case "D":
        return "default";
      case "RZ":
        return "restricted_area";
      case "GZ":
        return "green_zone";
      case "SZ":
        return "speed_limit_area";
      case "AS":
        return "stop";
      case "AI":
        return "idle";
      case "APON":
        return "pto_gate1_on";
      case "ATEMP":
        return "temperature";
      case "A_WIGP":
        return "working_within_a_given_period";
      case "A_WLTL":
        return "work_longer_than_the_time_limit";
      case "A_WDLR":
        return "work_distance_less_than_rule";
      case "A_WLTD":
        return "vehicle_move_out_factory_long_time";
      case "A_VMOFLT":
        return "vehicle_move_out_factory_long_time";
      case "A_VDC":
        return "vehicle_disconnect";
      case "A_VCD":
        return "vehicle_continous_driving";
      case "SEVT":
        return "speed_limit_of_the_event";

      default:
        break;
    }
  };

  const typeOfPolicy = {
    D: {
      subColumn: [
        {
          title: "ความเร็ว",
          dataIndex: "speed",
          render: (text) => <div>{text}</div>,
        },
      ],
    },
    RZ: {
      subColumn: [],
    },
    GZ: {
      subColumn: [],
    },
    SZ: {
      subColumn: [
        {
          title: "ความเร็ว",
          dataIndex: "speed",
          render: (text) => <div>{text}</div>,
        },
      ],
    },
    AS: {
      subColumn: [
        {
          title: "ชื่อสถานที่",
          dataIndex: "system_location",
          width: "200px",
          render: (text) => <div>{!!text ? text : "-"}</div>,
        },
      ],
    },
    AI: {
      subColumn: [
        {
          title: "ชื่อสถานที่",
          dataIndex: "system_location",
          width: "200px",
          render: (text) => <div>{!!text ? text : "-"}</div>,
        },
      ],
    },
    APON: {
      subColumn: [
        {
          title: "ชื่อสถานที่",
          dataIndex: "system_location",
          width: "200px",
          render: (text) => <div>{!!text ? text : "-"}</div>,
        },
      ],
    },
    ATEMP: {
      subColumn: [
        {
          title: "อุณหภูมิ",
          dataIndex: "temp",
          render: (text) => <div>{text}</div>,
        },
      ],
    },
    A_WIGP: {
      subColumn: [],
    },
    A_WLTL: {
      subColumn: [],
    },
    A_WDLR: {
      subColumn: [],
    },
    A_WLTD: {
      subColumn: [],
    },
    A_VMOFLT: {
      subColumn: [],
    },
    A_VDC: {
      subColumn: [],
    },
    A_VDC: {
      subColumn: [],
    },
    SEVT: {
      subColumn: [],
    },
  };

  const geoCode = {
    title: <>GeoCode</>,
    dataIndex: "address",
    width: 200,
    render: (text, record) => <div>{text}</div>,
  };

  const actionColumn = {
    title: "Action",
    key: "action",
    align: "center",
    fixed: "right",
    width: 130,
    render: (text, record) => (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Tooltip title={"รายละเอียดหน้านโยบาย"} placement="left">
          {record.is_policy ? (
            <Link to={`/policy-new/edit/${record.topic_id}`}>
              <Icon
                type="info-circle"
                style={{
                  color: "black",
                  cursor: "pointer",
                  paddingTop: 3,
                }}
              />
            </Link>
          ) : (
            <Link>
              <Icon
                type="info-circle"
                style={{
                  color: "gray",
                  cursor: "not-allowed",
                  paddingTop: 3,
                }}
              />
            </Link>
          )}
        </Tooltip>

        <Tooltip title={"ติดตามพาหนะ"} placement="left">
          <Link
            // to={`/tracking/vehicle/${54361}/${auth.profile.company_id}`}
            to={`/tracking/vehicle/${record.vehicle_id}/${auth.profile.company_id}`}
            style={{ cursor: "pointer" }}
          >
            <div style={{ width: "16px", height: "16px" }}>
              <TrackingReport stroke={"black"} />
            </div>
          </Link>
        </Tooltip>

        <Tooltip title={"วิดีโอเรียลไทม์"} placement="left">
          <Link
            // to={`/livestream?imei=${863462062838063}&vehicle_id=${54407}`}
            to={`/livestream?imei=${record.hardware_id}&vehicle_id=${record.vehicle_id}`}
            style={{ cursor: "pointer" }}
          >
            <VideoRealTimeReport fill={"black"} />
          </Link>
        </Tooltip>

        <Tooltip title={"เส้นทางเดินทางย้อนหลัง"} placement="left">
          <a
            onClick={() => {
              window.location.replace(
                `${window.location.origin}/replay?vehicle_id=${
                  record.vehicle_id
                }&start_at=${moment(
                  record.start_at,
                  "YYYY-MM-DD HH:mm:ss"
                ).format("YYYY-MM-DD 00:00:00")}&end_at=${moment(
                  record.end_at,
                  "YYYY-MM-DD HH:mm:ss"
                ).format("YYYY-MM-DD 23:59:59")}`
              );
            }}
            // vehicleid|hardwareid
            style={{ cursor: "pointer" }}
          >
            <Replay fill={"black"} />
          </a>
        </Tooltip>

        <Tooltip title={"ดาวน์โหลดวิดีโอ"} placement="left">
          <Link
            to={`/downloadvideoqueue?startDate=${moment(
              record.start_at,
              "YYYY-MM-DD hh:mm:ss"
            ).format("DD/MM/YYYY")}&endDate=${moment(
              record.end_at,
              "YYYY-MM-DD hh:mm:ss"
            ).format("DD/MM/YYYY")}&vehicle_id=${record.vehicle_id}`}
            style={{ cursor: "pointer" }}
          >
            <DownloadVideo fill={"black"} />
          </Link>
        </Tooltip>
      </div>
    ),
  };

  const dynamicColumns = [
    ...baseColumns,
    ...(typeOfPolicy[filterObj["subType"]] &&
    typeOfPolicy[filterObj["subType"]].subColumn
      ? typeOfPolicy[filterObj["subType"]].subColumn
      : []),
    filterObj["type"] == "event" && filterObj["policyId"] == "7"
      ? {
          title: "ความเร็ว",
          dataIndex: "speed",
          align: "center",
          render: (text) => <div>{!!text ? text.toFixed(2) : "-"}</div>,
        }
      : {},
    geoCode,
    actionColumn,
  ];

  return (
    <Spin spinning={loading}>
      <TableStyled
        className="tracking-table-v2"
        columns={dynamicColumns}
        dataSource={dataTable}
        pagination={false}
        scroll={{ y: 500, x: 1300 }}
      />
      {isMapVisible && locationLatLng.length > 0 && (
        <div className="custom-map-container">
          <button className="close-button" onClick={handleClose}>
            x
          </button>
          <CustomMap
            data-cy="map"
            bootstrapURLKeys={{
              // key: "AIzaSyCBh7O-7raiK3L0zOguKR1uAryIwBCQFA4",
              key: auth.profile.google_map_key,
              libraries: process.env.REACT_APP_GOOGLE_MAP_USING_LIBRARIES.split(
                ","
              ),
              region: "thailand",
              language: "th",
            }}
            center={defaultMapValue.center}
            defaultZoom={defaultMapValue.zoom}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
            options={{
              mapTypeControl: false,
              fullscreenControl: false,
              zoomControl: false,
              maxZoom: 19,
              streetViewControl: false,
              styles: [
                {
                  featureType: "poi.business",
                  stylers: [{ visibility: "on" }],
                },
                {
                  featureType: "poi.park",
                  elementType: "labels.text",
                  stylers: [{ visibility: "on" }],
                },
              ],
            }}
          >
            {locationLatLng.map((item) => (
              <LocationInfoMarker
                data-cy={1}
                key={1}
                lat={item.lat}
                lng={item.lng}
                title={item.plateNo}
              />
            ))}
          </CustomMap>
        </div>
      )}
    </Spin>
  );
};

export default TableTab;
