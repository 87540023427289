// Libraries
import React, { Component } from "react";
import { connect } from "react-redux";
import { i18n } from "modules/shared/context/langContext";
import { Icon, Checkbox, Popover, Button, Tooltip } from "antd";

const checkList = [
  { key: "p", value: "plateNo", check_value: "plateNo" },
  { key: "v", value: "vehicleCode", check_value: "vehicleCode" },
]
class MarkerTitleDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      styles,
      displayType,
      onCheck,
      visible,
      handleChangeVisible
    } = this.props;

    return (
      <Popover
        content={
          <div className="checkbox-list-style">
            {/* ประเภทสถานที่ */}
            {checkList.map((item) => {
              return (
                <div>
                  <Checkbox
                    value={item.check_value}
                    checked={displayType.includes(item.check_value)}
                    onChange={(e) => {
                      onCheck(e.target.checked, item.check_value);
                    }}
                  >
                    <span>
                      {i18n[this.props.language][item.key][item.value]}
                    </span>
                  </Checkbox>
                </div>
              );
            })}
          </div>
        }
        title={`${i18n[this.props.language].d.displayMarkerTitle}`}
        trigger="click"
        placement="leftBottom"
        overlayClassName="filter-popover-style"
        visible={visible}
        onVisibleChange={(visible) => {
          handleChangeVisible(visible);
        }}
      >
        <Tooltip
          placement="left"
          title={i18n[this.props.language].d.displayMarkerTitle}
        >
          <Button style={styles}>
            <Icon type="car" />
          </Button>
        </Tooltip>
      </Popover>
    );
  }
}

export default connect(
  ({ ui: { language } }) => ({
    language,
  }),
  {}
)(MarkerTitleDisplay);
