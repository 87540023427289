import React, { Component, Fragment } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import moment from "moment";
import {
  Button,
  Form,
  DatePicker,
  AutoComplete,
  Select,
  Spin,
  Badge,
  Modal,
} from "antd";
import LangContext from "modules/shared/context/langContext";
import { genDeviceProcessDetail } from "modules/monitoring/honeytoastdownloadfile/components/deviceProcessFunc";

const FormItem = Form.Item;
const { RangePicker } = DatePicker;
const { Option, OptGroup } = AutoComplete;

export default class Filter extends Component {
  state = {
    platenolists: [],
  };

  handleOpenDeviceProcessModal = () => {
    let html = genDeviceProcessDetail(this.props.deviceProcess.userList);

    Modal.warning({
      title: "อุปกรณ์นี้ กำลังมีการเชื่อมต่อการใช้งานวีดีโอดังนี้",
      content: <div dangerouslySetInnerHTML={{ __html: html }}></div>,
      okText: "ปิด",
    });
  };

  changeVehiclePlateno = (value, option, setFieldValue) => {
    setFieldValue("plate_no", value);
    setFieldValue("old_format", "");
    setFieldValue("imei", "");
    setFieldValue("vehicle_id", "");

    if (typeof option !== "undefined") {
      setFieldValue(
        "should_show_fileopt",
        this.convertToBoolean(option.props.data_otp)
      );
      setFieldValue(
        "has_file_opt",
        this.convertToBoolean(option.props.data_otp)
      );
      setFieldValue("device_types_id", option.props.data_type);
    } else {
      setFieldValue("should_show_fileopt", false);
      setFieldValue("has_file_opt", false);
      setFieldValue("device_types_id", "");
    }

    setFieldValue("file_size", "large");
    setFieldValue("camera_position", "");
  };

  selectVehiclePlateno = (value, option, setFieldValue) => {
    setFieldValue("plate_no", option.props.data_value);
    setFieldValue("imei", option.props.label);
    setFieldValue("vehicle_id", value);
    setFieldValue("old_format", option.props.data_format);

    setFieldValue("has_file_opt", this.convertToBoolean(option.props.data_otp));
    setFieldValue(
      "should_show_fileopt",
      this.convertToBoolean(option.props.data_otp)
    );
    setFieldValue("device_types_id", option.props.data_type);
    setFieldValue("file_size", "large");
    setFieldValue("camera_position", "");
  };

  generateCameraOption = (imei) => {
    if (imei === "") {
      return this.defaultCameraOption();
    }

    let rec = this.props.vehicleOnly.find((x) => x.imei === imei);
    if (typeof rec === "undefined") {
      return this.defaultCameraOption();
    } else if (rec.camera_channel.length > 0) {
      let list = [{ index: "", name: "ทั้งหมด" }, ...rec.camera_channel];

      return list.map((x) => {
        return (
          <Option key={x.index.toString()} value={x.index.toString()}>
            {x.name}
          </Option>
        );
      });
    }

    return this.defaultCameraOption();
  };

  defaultCameraOption = () => {
    let list = [
      { value: "", label_index: "a", label: "all" },
      { value: "front", label_index: "f", label: "front" },
      { value: "back", label_index: "c", label: "camera_back" },
      { value: "extra", label_index: "c", label: "camera_extra" },
    ];

    return list.map((x) => {
      return (
        <Option key={x.value} value={x.value}>
          <LangContext.Consumer>
            {(i18n) => i18n[x.label_index][x.label]}
          </LangContext.Consumer>
        </Option>
      );
    });
  };

  convertToBoolean = (value) => {
    if (value === "true") {
      return true;
    }

    if (value === "false") {
      return false;
    }

    return value;
  };

  render() {
    const { onSubmit, vehicleLists, loadVehicle, deviceProcess } = this.props;
    const platenolists = vehicleLists
      .filter((x) => x.title !== "fileopt")
      .map((group) => (
        <OptGroup
          key={group.title}
          label={
            <span style={{ color: "blue" }}>
              <LangContext.Consumer>
                {(i18n) => i18n.o[group.title]}
              </LangContext.Consumer>
            </span>
          }
        >
          {group.children.map((opt) => {
            return (
              <Option
                key={opt.id}
                label={opt.imei}
                data_value={opt.plate_no}
                data_format={opt.old_format.toString()}
                data_otp={opt.has_file_opt.toString()}
                data_type={opt.device_types_id}
                disabled={group.title === "offline" ? true : false}
              >
                {group.title === "online" ? (
                  <Badge color="#00BF06" text={opt.plate_no} />
                ) : (
                  <Badge color="#c91c1c" text={opt.plate_no} />
                )}
              </Option>
            );
          })}
        </OptGroup>
      ));

    return (
      <Fragment>
        <Formik
          initialValues={this.props.reInitData}
          enableReinitialize
          validate={(values) => {
            let errors = {};
            if (values.eventdate.length > 0) {
              const date_start = moment(values.eventdate[0]["_d"]);
              const date_end = moment(values.eventdate[1]["_d"]);
              let day_diff = date_end.diff(date_start, "days") + 1;
              if (day_diff > 1) {
                errors.eventdate = "ช่วงวันที่ที่เลือกไม่ควรเกิน 1 วัน";
              }
            }
            return errors;
          }}
          validationSchema={yup.object().shape({
            plate_no: yup.string().required("กรุณาเลือกทะเบียนพาหนะ"),
            eventdate: yup.array().required("กรุณาเลือกช่วงวันที่"),
          })}
          onSubmit={(values) => {
            onSubmit(values);
          }}
          render={({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            handleFocus,
            setFieldValue,
            resetForm,
          }) => (
            <Form onSubmit={handleSubmit} layout={"inline"}>
              <FormItem>
                <Button
                  type="danger"
                  disabled={deviceProcess === null}
                  icon="eye"
                  size="small"
                  onClick={this.handleOpenDeviceProcessModal}
                />
              </FormItem>
              <FormItem
                label={
                  <LangContext.Consumer>
                    {(i18n) => i18n.p.plateNo}
                  </LangContext.Consumer>
                }
                validateStatus={touched.plate_no && errors.plate_no && "error"}
                help={touched.plate_no && errors.plate_no}
              >
                <AutoComplete
                  dataSource={platenolists}
                  style={{ width: "300px" }}
                  optionLabelProp="children"
                  filterOption={(inputValue, option) => {
                    if (typeof option.props.children.props !== "undefined") {
                      return (
                        option.props.children.props.text
                          .toLowerCase()
                          .indexOf(inputValue.toLowerCase()) !== -1
                      );
                    }
                    return false;
                  }}
                  optionFilterProp="children"
                  placeholder="ค้นหาทะเบียน"
                  value={values.plate_no}
                  onFocus={this.props.loadData}
                  onSearch={(value, option) =>
                    this.changeVehiclePlateno(value, option, setFieldValue)
                  }
                  onSelect={(value, option) =>
                    this.selectVehiclePlateno(value, option, setFieldValue)
                  }
                  notFoundContent={loadVehicle ? <Spin size="small" /> : null}
                />
              </FormItem>

              <FormItem
                label={
                  <LangContext.Consumer>
                    {(i18n) => i18n.c.camera_position}
                  </LangContext.Consumer>
                }
                validateStatus={
                  touched.camera_position && errors.camera_position && "error"
                }
                help={touched.camera_position && errors.camera_position}
              >
                <Select
                  onChange={(value) => {
                    setFieldValue("camera_position", value);
                    // กล้องหน้า hnt เท่านั้นที่มีไฟล์เล็ก
                    if (values.device_types_id == 32) {
                      if (
                        ["front", ""].includes(value) &&
                        values.has_file_opt
                      ) {
                        setFieldValue("should_show_fileopt", true);
                      } else {
                        setFieldValue("should_show_fileopt", false);
                        setFieldValue("file_size", "large");
                      }
                    }

                    if (values.device_types_id == 37) {
                      if (value === "extra") {
                        setFieldValue("should_show_fileopt", false);
                        setFieldValue("file_size", "large");
                      } else {
                        setFieldValue("should_show_fileopt", true);
                      }
                    }
                  }}
                  value={values.camera_position}
                >
                  {this.generateCameraOption(values.imei)}
                </Select>
              </FormItem>

              {values.should_show_fileopt && (
                <FormItem
                  label={
                    <LangContext.Consumer>
                      {(i18n) => i18n.f.file_size}
                    </LangContext.Consumer>
                  }
                >
                  <Select
                    onChange={(value) => setFieldValue("file_size", value)}
                    value={values.file_size}
                  >
                    <Option value="small">
                      <LangContext.Consumer>
                        {(i18n) => i18n.f.file_size_small}
                      </LangContext.Consumer>
                    </Option>
                    <Option value="large">
                      <LangContext.Consumer>
                        {(i18n) => i18n.f.file_size_large}
                      </LangContext.Consumer>
                    </Option>
                  </Select>
                </FormItem>
              )}

              <Form.Item
                label={
                  <LangContext.Consumer>
                    {(i18n) => i18n.d.dateRange}
                  </LangContext.Consumer>
                }
                validateStatus={errors.eventdate && "error"}
                help={errors.eventdate}
              >
                <RangePicker
                  showTime={{ format: "HH:mm:ss" }}
                  onChange={(value) => setFieldValue("eventdate", value)}
                  format="DD/MM/YYYY HH:mm:ss"
                  placeholder={["Start Time", "End Time"]}
                  value={values.eventdate}
                />
              </Form.Item>

              <FormItem>
                <Button htmlType="submit" type="primary">
                  <LangContext.Consumer>
                    {(i18n) => i18n.a.apply}
                  </LangContext.Consumer>
                </Button>
              </FormItem>
            </Form>
          )}
        />
      </Fragment>
    );
  }
}
