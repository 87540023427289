import React, { useState, useEffect, useRef } from "react";
import { GeneralStyledContent } from "../../../../styled/common-styled";
import { Tabs, Row, Col, Button } from "antd";
import LangContext from "modules/shared/context/langContext";
import { connect } from "react-redux";
import AuthorizeComponent from "../../../auth/components/AuthorizeComponent";
import Breadcrumb from "../../../shared/components/Breadcrumb";
import SummaryTab from "./SummaryTab";
import CustomTab from "./CustomTab";
import TableTab from "./TableTab";
import Filter from "./Filter";
import moment from "moment";

const Index = (props) => {
  const [currentTab, setCurrentTab] = useState(0);

  const [filterObj, setFilterObj] = useState({
    policyId: undefined,
    type: undefined,
    subType: undefined,
    vehicleSelected: undefined,
    vehicleGroupId: [],
    dateTime: [moment(), moment()],
  });

  const NOTI_CENTER_TAB = [
    {
      label: "Summary",
      id: 0,
    },
    {
      label: "Custom",
      id: 1,
    },
    {
      label: "Table",
      id: 2,
    },
  ];

  const currentComponent = (currId) => {
    switch (currId) {
      case 0:
        return <SummaryTab />;
      case 1:
        return <CustomTab />;
      case 2:
        return (
          <TableTab
            filterObj={filterObj}
            auth={props.auth}
            setFilterObj={setFilterObj}
          />
        );

      default:
        break;
    }
  };

  const handleTab = (id) => {
    setCurrentTab(id);
  };

  return (
    // <AuthorizeComponent matching_name="newsManual">
    <LangContext.Consumer>
      {(i18n) => (
        <GeneralStyledContent>
          <Row>
            <Col span={24}>
              <Breadcrumb
                match={props.match}
                style={{ margin: "0px 0px 14px 10px" }}
              />
            </Col>
            <Col span={24}>
              <Row
                gutter={[16, 16]}
                type="flex"
                justify="space-around"
                align="middle"
              >
                {NOTI_CENTER_TAB.map((item) => (
                  <Col span={2}>
                    <Button
                      type={item.id == currentTab ? "primary" : "link"}
                      style={{
                        backgroundColor: item.id == currentTab ? "#3f87e4" : "",
                        color: item.id == currentTab ? "white" : "black",
                      }}
                      className="hover-tab-noti-center"
                      onClick={() => handleTab(item.id)}
                    >
                      <label style={{ padding: 10, cursor: "pointer" }}>
                        {item.label}
                      </label>
                    </Button>
                  </Col>
                ))}
                <Col span={18} align="right">
                  <Filter
                    profile={props.auth.profile}
                    onSubmit={(value) => {
                      setFilterObj(value);
                    }}
                    filterObj={filterObj}
                  />
                </Col>
                <Col span={24}>{currentComponent(currentTab)}</Col>
              </Row>
            </Col>
          </Row>
        </GeneralStyledContent>
      )}
    </LangContext.Consumer>
    // </AuthorizeComponent>
  );
};

export default connect(
  ({ auth }) => ({
    auth,
  }),
  {}
)(Index);
