import React, { useState, useEffect } from "react";
import { LandingPageNewsAndManuals } from "../../../../styled/common-styled";
import LangContext from "modules/shared/context/langContext";
import { Tabs, Row, Col, Empty, Spin } from "antd";
import TabLists from "./TabLists";
import RichText from "./Richtext";
import { getNewsCategory, getNews } from "../../fetch/apiclient";

const { TabPane } = Tabs;

const News = () => {
  const [list, setList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [detail, setDetail] = useState(null);
  const [isActiveId, setIsActiveId] = useState(null);
  const [loadingRichText, setLoadingRichText] = useState(false);
  const [header, setHeader] = useState({
    topic: "",
    description: "",
    cover: "",
  });

  useEffect(() => {
    getNewsCategory({}, (status, res) => {
      setCategoryList(res.data.data);
      getNews({ is_publish: true }, (status, resNews) => {
        setList(resNews.data.data);
      });
    });
  }, []);

  const handleSelectType = (val) => {
    setDetail(null);
    setIsActiveId(val.id);
    setLoadingRichText(true);
    setTimeout(() => {
      callBackSelectedNews(val);
    }, 500);
  };

  const callBackSelectedNews = (val) => {
    console.log("callBackSelectedNews", val);
    let { topic, description, cover } = val;
    setDetail(val.detail);
    setHeader({
      topic,
      description,
      cover,
    });
    setLoadingRichText(false);
  };

  return (
    <LandingPageNewsAndManuals>
      <LangContext.Consumer>
        {(i18n) => (
          <Row>
            <Col span={8}>
              <Tabs
                defaultActiveKey="0"
                onChange={(val) => console.log(val)}
                className="TabsStylingHomeDashboard"
              >
                <TabPane tab={i18n.a.all} key="0">
                  <TabLists
                    list={list}
                    handleSelectType={handleSelectType}
                    isActiveId={isActiveId}
                  />
                </TabPane>
                {categoryList.map((item) => (
                  <TabPane tab={i18n.newsManuals[`${item.name}`]} key={item.id}>
                    <TabLists
                      list={list.filter(
                        (listVal) => listVal.news_category_id == item.id
                      )}
                      handleSelectType={handleSelectType}
                      isActiveId={isActiveId}
                    />
                  </TabPane>
                ))}
              </Tabs>
            </Col>
            <Col span={16}>
              <Spin spinning={loadingRichText}>
                <div
                  style={{
                    borderLeft: "1px solid rgb(232 232 232)",
                    minHeight: window.innerHeight - 200,
                    height: "auto", // ปรับความสูงตามเนื้อหา
                    overflowY: "auto",
                  }}
                >
                  <div style={{ padding: "10px 10px 0px 10px" }}>
                    <h1 style={{ fontSize: 32, margin: 0 }}>{header.topic}</h1>
                    <p style={{ fontSize: 16, marginBottom: 8 }}>
                      {header.description}
                    </p>
                    <img
                      src={header.cover}
                      style={{ width: "100%", height: "auto" }}
                    />
                  </div>

                  {detail ? (
                    <RichText
                      description={detail}
                      disabled={true}
                      setDescription={(val) => {}}
                    />
                  ) : (
                    <Empty style={{ paddingTop: window.innerHeight - 700 }} />
                  )}
                </div>
              </Spin>
            </Col>
          </Row>
        )}
      </LangContext.Consumer>
    </LandingPageNewsAndManuals>
  );
};

export default News;
