import React, { useState, useEffect } from "react";
import { loadTopic, loadVehicleByPolicy } from "../fetchAPI/apiClient";
import { Row, Col, Select, DatePicker, Button } from "antd";
import LangContext, { i18n } from "modules/shared/context/langContext";
import moment from "moment";

const { RangePicker } = DatePicker;

const { Option } = Select;

const Filter = ({ profile, onSubmit, filterObj }) => {
  const [policyType, setPolicyType] = useState([]);
  const [vehicleList, setVehicleList] = useState([]);
  const [filterObject, setFilterObject] = useState({
    policyId: undefined,
    type: undefined,
    subType: undefined,
    vehicleSelected: undefined,
    vehicleGroupId: [],
    dateTime: [moment(), moment()],
  });
  useEffect(() => {
    loadTopic(
      // 175,
      profile.id,
      (statusCode, response) => {
        setPolicyType(response.data.data);
      }
    );
  }, []);

  useEffect(() => {
    if (filterObj.vehicleSelected != filterObject.vehicleSelected) {
      setFilterObject((prev) => ({
        ...prev,
        vehicleSelected: filterObj.vehicleSelected.toString(),
      }));
    }
  }, [filterObj]);

  const onChangeDatepicker = (date, dateString) => {
    setFilterObject((prev) => ({ ...prev, dateTime: date }));
  };

  return (
    <Row gutter={[8, 8]}>
      <Col span={7} align="right">
        <Select
          value={filterObject.policyId}
          placeholder="ชนิดนโยบาย"
          style={{ width: "100%" }}
          onChange={(e) => {
            setFilterObject((prev) => {
              let getType = policyType.find((val) => val.topic_id == e);
              loadVehicleByPolicy(
                {
                  user_id: profile.id.toString(),
                  topic_id: [e],
                  topic_type: getType.topic_type,
                },
                (status, res) => {
                  setVehicleList(res.data.data);
                }
              );
              return {
                ...prev,
                policyId: e,
                type: getType.topic_type,
                subType: getType.topic_sub_type,
              };
            });
          }}
        >
          {policyType.map((item) => (
            <Option key={item.topic_id.toString()} value={item.topic_id}>
              {item.topic_name}
            </Option>
          ))}
        </Select>
      </Col>
      <Col span={7} align="right">
        <Select
          disabled={!!filterObject.type ? false : true}
          value={filterObject.vehicleSelected}
          placeholder="เลือกพาหนะ"
          style={{ width: "100%" }}
          onChange={(e) => {
            if (e == undefined) {
              setFilterObject((prev) => {
                return {
                  ...prev,
                  vehicleSelected: undefined,
                };
              });
            } else {
              setFilterObject((prev) => {
                return {
                  ...prev,
                  vehicleSelected: e.toString(),
                };
              });
            }
          }}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          allowClear
          showSearch
        >
          {vehicleList.map((item) => (
            <Option key={item.id.toString()} value={item.id}>
              {`${item.plate_no} (${item.code})`}
            </Option>
          ))}
        </Select>
      </Col>
      <Col span={7} align="center">
        <RangePicker
          onChange={onChangeDatepicker}
          style={{ width: "100%" }}
          value={filterObject.dateTime}
        />
      </Col>
      <Col span={2} align="right">
        <Button
          type="primary"
          style={{
            width: "100%",
            backgroundColor: "#3f87e4",
            color: "white",
            borderRadius: 16,
          }}
          onClick={() => {
            onSubmit(filterObject);
          }}
        >
          <LangContext.Consumer>{(i18n) => i18n.s.search}</LangContext.Consumer>
        </Button>
      </Col>
      <Col span={1} align="right">
        <Button icon="upload" disabled />
      </Col>
    </Row>
  );
};

export default Filter;
